import { sessionService } from 'redux-react-session';
import axios from 'axios';
import * as endpoints from '../constants/api';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
});

async function saveSession(params) {
  const { data } = await axiosInstance.post(endpoints.API_TOKEN_PATH, params);
  const { user, scope, ...session } = data;

  user.scope = scope;
  await sessionService.saveSession(session);
  await sessionService.saveUser(user);
}

export async function getToken(email, password) {
  const params = new URLSearchParams();

  params.append('grant_type', 'password');
  params.append('username', email);
  params.append('password', password);
  params.append('client_id', process.env.REACT_APP_CLIENT_ID);

  await saveSession(params);
}

export async function refreshToken(credentials) {
  const params = new URLSearchParams();

  params.append('grant_type', 'refresh_token');
  params.append('refresh_token', credentials.refresh_token);
  params.append('client_id', process.env.REACT_APP_CLIENT_ID);

  return saveSession(params);
}

export async function validateSession(credentials) {
  const isExpired = new Date(credentials.access_token_expires_at) < Date.now();

  if (!isExpired) {
    return true;
  }

  await refreshToken(credentials);
  return true;
}
