import React from 'react';
import {
  Formik, Form, ErrorMessage, Field,
} from 'formik';
import * as Yup from 'yup';
import * as PropTypes from 'prop-types';
import {
  FormGroup, InputGroup, ControlLabel, Icon, Button,
} from 'rsuite';

const getValidationSchema = () => Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});

export default function LoginForm(props) {
  const {
    onSubmit,
    isLoading,
  } = props;

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(false);
    actions.setFieldValue('password', '');
    actions.setFieldTouched('password', false);
    onSubmit(values.email, values.password);
  };

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={handleSubmit}
      validationSchema={getValidationSchema()}
    >
      {(formik) => (
        <Form data-testid="login-form">
          <FormGroup controlId="loginEmail">
            <ControlLabel className={formik.touched.email && formik.errors.email ? 'is-invalid' : ''}>
              Correo electrónico
            </ControlLabel>
            <InputGroup inside className={formik.touched.email && formik.errors.email ? 'is-invalid' : ''}>
              <InputGroup.Addon>
                <Icon icon="envelope-o" />
              </InputGroup.Addon>
              <Field name="email">
                {({ field }) => (
                  <input {...field} id="loginEmail" type="email" className="rs-input" placeholder={field.value} noValidate />
                )}
              </Field>
            </InputGroup>
            <ErrorMessage name="email">
              {(msg) => <span className="invalid-feedback">{msg}</span>}
            </ErrorMessage>
          </FormGroup>
          <FormGroup controlId="loginPassword">
            <ControlLabel className={formik.touched.password && formik.errors.password ? 'is-invalid' : ''}>
              Contraseña lok
            </ControlLabel>
            <InputGroup inside className={formik.touched.password && formik.errors.password ? 'is-invalid' : ''}>
              <InputGroup.Addon>
                <Icon icon="lock" />
              </InputGroup.Addon>
              <Field name="password">
                {({ field }) => (
                  <input {...field} id="loginPassword" className="rs-input" type="password" placeholder={field.value} noValidate />
                )}
              </Field>
            </InputGroup>
            <ErrorMessage name="password">
              {(msg) => <span className="invalid-feedback">{msg}</span>}
            </ErrorMessage>
          </FormGroup>
          <a href="/#">¿Olvidaste tu contraseña?</a>
          <Button
            type="submit"
            appearance="primary"
            className="mt-5"
            block
            sizes="lg"
            loading={isLoading}
            disabled={!formik.dirty || isLoading}
          >
            Iniciar sesión
          </Button>
          <p className="text-muted">
            Al ingresar, estás aceptando los&nbsp;
            <a href="/#">Términos y Condiciones.</a>
          </p>
        </Form>
      )}
    </Formik>
  );
}

LoginForm.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
};
