import React from 'react';
import { List, Placeholder } from 'rsuite';

export default function ListLoading() {
  return (
    <List className="Reservation__list--loading">
      <List.Item>
        <Placeholder.Paragraph rows={3} rowMargin={5} active />
      </List.Item>
      <List.Item>
        <Placeholder.Paragraph rows={3} rowMargin={5} active />
      </List.Item>
    </List>
  );
}
