import { Date } from 'sugar/date';
import 'sugar/locales/es';

Date.setLocale('es');

export function dateAgo(str) {
  const sdate = new Date();

  sdate.rewind(str);
  return sdate.format('{X}').raw;
}

export function relativeStr(str) {
  return new Date(str).relative('es').raw;
}

export function formatComponents(str) {
  const date = new Date(str);

  return {
    date: date.short().raw,
    time: date.format('%R').raw,
    full: date.long().raw,
  };
}
