import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import chroma from 'chroma-js';
import * as actions from '../../actions/locations';
import LocationDisplay from './LocationDisplay';

import './LocationPicker.less';

export default function LocationPicker() {
  const dispatch = useDispatch();
  const locations = useSelector((store) => store.locations.data);
  const value = useSelector((store) => store.locations.selectedLocation);

  // eslint-disable-next-line no-unused-vars
  const handleChange = (item, _) => {
    dispatch(actions.selectLocation(item));
  };

  useEffect(() => {
    dispatch(actions.getLocations());
  }, []);

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      padding: 10,
      zIndex: 10,
    }),
    option: (provided, { isFocused, isSelected }) => {
      const lokRed = '#FF4E54';
      return {
        ...provided,
        // eslint-disable-next-line no-nested-ternary
        color: isSelected ? 'white' : isFocused ? 'white' : null,
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: isFocused
          ? chroma(lokRed).alpha(0.7).css()
          : isSelected
            ? chroma(lokRed).alpha(0.8).css()
            : null,
        fontSize: 12,
        ':hover': {
          ...provided[':hover'],
          cursor: 'pointer',
          backgroundColor: isFocused && isSelected ? lokRed : null,
        },
        ':active': {
          ...provided[':active'],
          backgroundColor: chroma(lokRed).alpha(0.9).css(),
          color: 'white',
        },
      };
    },
    control: (provided, state) => {
      const location = state.getValue();
      const isAvailable = locations.find((loc) => loc.id === location[0]?.id)
        ?.available;
      return {
        ...provided,
        border: isAvailable ? '2px solid #33B79F' : '2px solid #F37070',
      };
    },
  };

  // eslint-disable-next-line react/prop-types
  const ValueContainer = ({ children, ...props }) => {
    const { getValue } = { ...props };
    const valueSelected = getValue();
    return (
      <components.ValueContainer {...props}>
        {valueSelected.length === 1 && (
          <LocationDisplay value={valueSelected[0]} />
        )}
        {!valueSelected && children}
      </components.ValueContainer>
    );
  };

  return (
    <Select
      placeholder="Search..."
      autoFocus
      filterOption=""
      backspaceRemovesValue={false}
      isClearable={false}
      controlShouldRenderValue
      tabSelectsValue={false}
      hideSelectedOptions={false}
      value={value}
      onChange={handleChange}
      width="100%"
      options={locations}
      components={{ ValueContainer, IndicatorSeparator: null }}
      styles={customStyles}
    />
  );
}
