import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import {
  Panel, FlexboxGrid, Col, Alert,
} from 'rsuite';
import logo from '../logo.svg';
import Login from '../components/Auth/LoginForm';
import { loginRequest } from '../actions/auth';
import * as routes from '../constants/routes';
import './Login.less';

export default function LoginPage(props) {
  const { location } = props;
  const dispatch = useDispatch();
  const isVerified = useSelector((store) => store.session.checked);
  const isAuthenticated = useSelector((store) => store.session.authenticated);
  const isLoading = useSelector((store) => store.auth.isLoading);
  const isError = useSelector((store) => store.auth.isError);
  const message = useSelector((store) => store.auth.message);
  const redirectTo = location.state?.from?.pathname || routes.HOME_PATH;
  const onSubmit = (email, password) => {
    dispatch(loginRequest(email, password));
  };

  useEffect(() => {
    if (isError) {
      Alert.config({ top: window.pageYOffset });
      Alert.error(`Error: ${message}`, 5000);
    }
  }, [isError, message]);

  if (isAuthenticated) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <FlexboxGrid justify="center" className="Login__container" align="middle">
      <FlexboxGrid.Item colspan={20} sm={16} md={12} lg={8} componentClass={Col}>
        <img src={logo} alt="Lok" className="mt-3" />
        <Panel bordered shaded className="bg-white mb-3">
          <Login
            isLoading={isLoading || !isVerified}
            onSubmit={onSubmit}
          />
        </Panel>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
