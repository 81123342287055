import React from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import { Container, Content, Header } from 'rsuite';

// Utils
import PrivateRoute from './components/Auth/PrivateRoute';
import Navbar from './components/Navbar/Navbar';

// Pages
import LoginPage from './pages/Login';
import DashboardPage from './pages/Dashboard';
import SupportPage from './pages/Support';
import TermsPage from './pages/TermsAndConditions';

// Constants
import * as routes from './constants/routes';

function App() {
  return (
    <Container>
      <Router>
        <Header>
          <Navbar />
        </Header>
        <Content>
          <Switch>
            <PrivateRoute
              exact
              path={routes.HOME_PATH}
              component={DashboardPage}
            />
            <PrivateRoute
              exact
              path={routes.SUPPORT_PATH}
              component={SupportPage}
            />
            <PrivateRoute
              exact
              path={routes.TERMS_AND_CONDITIONS_PATH}
              component={TermsPage}
            />
            <Route path={routes.LOGIN_PATH} component={LoginPage} />
          </Switch>
        </Content>
      </Router>
    </Container>
  );
}

export default App;
