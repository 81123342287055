import { Layers, Users, BookOpen } from 'react-feather';
import * as routes from '../../constants/routes';

const NAVIGATION_ROUTES = [
  {
    name: 'Reservaciones',
    path: routes.HOME_PATH,
    icon: Layers,
    key: 'reservations',
  },
  {
    name: 'Soporte',
    icon: Users,
    path: routes.SUPPORT_PATH,
    key: 'support',
  },
  {
    name: 'Términos y condiciones',
    icon: BookOpen,
    path: routes.TERMS_AND_CONDITIONS_PATH,
    key: 'terms',
  },
];

export default NAVIGATION_ROUTES;
