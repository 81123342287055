import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { sessionService } from 'redux-react-session';
import { setLocale } from 'yup';
import store from './configureStore';
import { validateSession } from './utils/auth';
import * as routes from './constants/routes';
import formErrors from './constants/yup';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import App from './App';
import './index.less';

async function setup() {
  if (process.env.NODE_ENV === 'development') {
    const {worker} = require('./mocks/browser');

    await worker.start();
  }

  console.log(`Running version: ${process.env.REACT_APP_VERSION}`);
  console.log(`Using API: ${process.env.REACT_APP_API_URL}`);
  console.log(`Environment: ${process.env.NODE_ENV}`);

  sessionService.initSessionService(store, {
    redirectPath: routes.LOGIN_PATH,
    driver: 'COOKIES',
    validateSession,
  });
  setLocale(formErrors);

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}

setup();

// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
