const RED = {
  color: '#FF4848',
};

const SMALL_ICON = {
  size: 24,
};

const LARGE_ICON = {
  size: 35,
};

const LARGE_ICON_RED = {
  ...RED,
  size: 35,
};

const MEDIUM_ICON = {
  size: 28,
};

const MEDIUM_ICON_RED = {
  ...RED,
  size: 28,
};

export {
  LARGE_ICON,
  LARGE_ICON_RED,
  MEDIUM_ICON_RED,
  MEDIUM_ICON,
  SMALL_ICON,
};
