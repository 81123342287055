import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import ReservationModal from './ReservationModal';
import { relativeStr } from '../../utils/dates';

export default function ReservationEntry(props) {
  const { data, status } = props;
  const [showDetail, setShow] = useState(false);
  const toggleShow = () => setShow(!showDetail);
  const isActive = status === 'active';
  const statusPrefix = isActive ? 'Depositado' : 'Recolectado';
  const notifyPrefix = isActive ? 'Notificado a' : 'Recolectado por';

  return (
    <>
      <button onClick={toggleShow}>
        <small>
          { statusPrefix }
          { ' ' }
          { relativeStr(data.updatedAt) }
        </small>
        <h6>{ data.shipmentId }</h6>
        <small>
          { notifyPrefix }
          { ' ' }
          { data.recipientEmail }
        </small>
      </button>
      <ReservationModal show={showDetail} onHide={toggleShow} data={data} />
    </>
  );
}

ReservationEntry.propTypes = {
  data: PropTypes.shape({
    shipmentId: PropTypes.string,
    updatedAt: PropTypes.string,
    recipientEmail: PropTypes.string,
    locationName: PropTypes.string,
  }),
  status: PropTypes.string,
};
