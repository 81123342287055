// eslint-disable-next-line import/no-extraneous-dependencies
import { setupWorker, rest } from 'msw';
import * as routes from '../constants/api';
import * as locations from './locationsData';
import * as reservations from './reservationsData';

const LOGIN_PATH = `${process.env.REACT_APP_API_URL}${routes.API_TOKEN_PATH}`;
const USER_PATH = `${process.env.REACT_APP_API_URL}${routes.API_USER_PATH}`;
const LOCATIONS_PATH = `${process.env.REACT_APP_API_URL}${routes.API_LOCATIONS_PATH}`;
const RESERVATIONS_PATH = `${process.env.REACT_APP_API_URL}${routes.API_RESERVATIONS_PATH}`;
const ACCESS_TOKEN_EXP = 10 * 60 * 1000;
const REFRESH_TOKEN_EXP = 24 * 60 * 60 * 1000;

// eslint-disable-next-line import/prefer-default-export
export const worker = setupWorker(
  rest.post(LOGIN_PATH, (req, res, ctx) => {
    const params = new URLSearchParams(req.body);

    if (params.get('grant_type') === 'refresh_token') {
      return res(ctx.json({
        access_token: 'anothertoken',
        refresh_token: 'anotherrefreshtoken',
        access_token_expires_at: new Date(Date.now() + ACCESS_TOKEN_EXP).toISOString(),
        refresh_token_expires_at: new Date(Date.now() + REFRESH_TOKEN_EXP).toISOString(),
        scope: 'owner',
        user: {
          email: params.get('username'),
          name: 'Fake user',
        },
      }));
    }

    const user = params.get('username').split('@').shift();

    if (user === 'invalid') {
      return res(
        ctx.status(401),
        ctx.json({
          type: 'INVALID_SCOPE',
          message: 'Not authorized',
        }),
      );
    }

    if (params.get('password') !== 'password') {
      return res(
        ctx.status(401),
        ctx.json({
          type: 'INVALID_GRANT',
          message: 'Not authorized',
        }),
      );
    }

    return res(ctx.json({
      access_token: 'abcdefghijklm',
      refresh_token: 'abcdefghijklm',
      access_token_expires_at: new Date(Date.now() + ACCESS_TOKEN_EXP).toISOString(),
      refresh_token_expires_at: new Date(Date.now() + REFRESH_TOKEN_EXP).toISOString(),
      scope: 'owner',
      user: {
        email: params.get('username'),
        name: 'Fake user',
      },
    }));
  }),
  rest.get(USER_PATH, (req, res, ctx) => res(ctx.json({
    user: {
      email: 'user@fake.com',
      scope: 'owner',
      name: 'Fake user',
    },
  }))),
  rest.get(LOCATIONS_PATH, (req, res, ctx) => res(ctx.json(locations.getLocations))),
  rest.get(RESERVATIONS_PATH, (req, res, ctx) => res(ctx.json(reservations.getReservations))),
);
