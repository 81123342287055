import { combineReducers } from 'redux';
import { sessionReducer } from 'redux-react-session';
import authReducer from './auth/reducer';
import locationsReducer from './locations/reducer';
import reservationsReducer from './reservations/reducer';

const allReducers = combineReducers({
  session: sessionReducer,
  auth: authReducer,
  locations: locationsReducer,
  reservations: reservationsReducer,
});

export default allReducers;
