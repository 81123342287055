import React from 'react';

import {
  LOK_SITE,
} from '../constants/support';

function TermsAndConditions() {
  return (
    <div>
      <h2>Términos y condiciones</h2>
      <p className="c56">
        <span className="c1">
          Los siguientes T&eacute;rminos y Condiciones generales (en adelante
          &quot;T&eacute;rminos y Condiciones&quot;) rigen el buen uso y manejo
          aplicable al contenido, productos y servicios ofrecidos por
          {' '}
        </span>
        <span className="c6 c2">ClicknBox, S.A.P.I. </span>
        <span className="c2">de</span>
        <span className="c6 c2">&nbsp;C.V. </span>
        <span className="c1">(en lo sucesivo &quot;</span>
        <span className="c6 c2">Lok</span>
        <span className="c1">
          &quot;), quien tiene su domicilio establecido en Avenida
          Ej&eacute;rcito Nacional 381 (trescientos ochenta y uno), Colonia
          Granada, Alcald&iacute;a de Miguel Hidalgo, C.P. 11520 (once mil
          quinientos veinte), Ciudad de M&eacute;xico, a trav&eacute;s de su
          sitio web
        </span>
        <span className="c6 c2">https://www.</span>
        <span className="c6 c2">{LOK_SITE}</span>
        <span className="c6 c2">.com </span>
        <span className="c1">
          (en adelante, &quot;Sitio Web&quot;) y mediante otros medios.
          {' '}
        </span>
      </p>
      <p className="c102">
        <span className="c1">
          Cualquier persona que dese&eacute; acceder o hacer uso del sitio web
          y/ o de los servicios y productos en lo sucesivo (&ldquo;el
          locker&rdquo;) ofrecidos por
          {' '}
        </span>
        <span className="c17">Lok</span>
        <span className="c1">
          , podr&aacute; hacerlo sujet&aacute;ndose a los presentes
          T&eacute;rminos y Condiciones aqu&iacute; descritos, as&iacute; como a
          las pol&iacute;ticas y principios incorporados al presente documento.
          En este sentido al momento de usar el sitio web y/o los productos y
          servicios ofrecidos por
        </span>
        <span className="c17">Lok</span>
        <span className="c1">
          , usted acepta y est&aacute; de acuerdo con los mismos. En el caso de
          que usted no
          {' '}
        </span>
        <span className="c17">est&eacute;</span>
        <span className="c1">
          &nbsp;de acuerdo con los t&eacute;rminos y condiciones aqu&iacute;
          descritos, deber&aacute; abstenerse de utilizar el Sitio Web y/o
          adquirir los productos y servicios ofrecidos por
          {' '}
        </span>
        <span className="c17">Lok</span>
        <span className="c1">. </span>
      </p>
      <p className="c102">
        <span className="c17">Lok</span>
        <span className="c1">
          &nbsp;se reserva el derecho de revisar y actualizar los
          T&eacute;rminos y Condiciones en cualquier momento, por lo que le
          aconsejamos revisar peri&oacute;dicamente la p&aacute;gina
          {' '}
        </span>
        <span className="c6 c2">https://www.</span>
        <span className="c2">{LOK_SITE}</span>
        <span className="c6 c2">.com </span>
        <span className="c1">
          para comprobar cualquier actualizaci&oacute;n al Sitio Web y el
          contenido incluido en el mismo. La fecha de entrada en vigor
          aparecer&aacute; siempre a pie de p&aacute;gina, y algunas
          disposiciones pueden ser sustituidas por avisos legales en el Sitio
          Web.
        </span>
      </p>
      <p className="c70">
        <span className="c6 c13">OBJETO </span>
      </p>
      <p className="c142">
        <span className="c6 c2">I.</span>
        <span className="c54 c2">- </span>
        <span className="c1">
          El objeto de los presentes T&eacute;rminos y Condiciones es regular el
          acceso, buen uso y manejo de la utilizaci&oacute;n del Sitio Web,
          as&iacute; como, de todos los productos y servicios que presta
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c1">
          , entendiendo por este cualquier tipo de contenido, producto o
          servicio que se encuentre a disposici&oacute;n de Usuarios y/o
          Clientes dentro y fuera del dominio
          {' '}
        </span>
        <span className="c6 c2">https://www.</span>
        <span className="c2">{LOK_SITE}</span>
        <span className="c6 c2">.com</span>
        <span className="c1">. </span>
      </p>
      <p className="c61">
        <span className="c6 c2">II.</span>
        <span className="c54 c2">- </span>
        <span className="c1">
          El Sitio Web est&aacute; dirigido principalmente a Usuarios y/o
          Clientes residentes en la Rep&uacute;blica Mexicana, por lo cual,
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          no asegura que el Sitio Web cumpla total o parcialmente con la
          legislaci&oacute;n de otros pa&iacute;ses, de forma que, si el Usuario
          y/o Clientes reside o tiene su domicilio establecido en otro
          pa&iacute;s y decide acceder o utilizar el Sitio Web lo har&aacute;
          bajo su propia responsabilidad y deber&aacute; asegurarse de que tal
          acceso y navegaci&oacute;n cumple con la legislaci&oacute;n local que
          le es aplicable,
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          no asumir&aacute; ninguna responsabilidad que se pueda derivar de
          dicho acto.
          {' '}
        </span>
      </p>
      <p className="c40">
        <span className="c6 c2">III.</span>
        <span className="c54 c2">- </span>
        <span className="c1">
          Se hace del conocimiento del Usuario y/o Cliente que
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          podr&aacute; administrar o gestionar el Sitio Web de manera directa o
          a trav&eacute;s de un tercero, lo cual no modifica en ning&uacute;n
          sentido lo establecido en los presentes T&eacute;rminos y Condiciones.
        </span>
      </p>
      <p className="c36">
        <span className="c6 c13">DEFINICIONES </span>
      </p>
      <p className="c120">
        <span className="c1">
          A continuaci&oacute;n, se definen algunos conceptos que se encuentran
          en estos T&eacute;rminos y Condiciones:
          {' '}
        </span>
      </p>
      <p className="c95">
        <span className="c6 c2">a) Box</span>
        <span className="c1">/</span>
        <span className="c6 c2">Locker inteligente</span>
        <span className="c1">
          : casillero o conjunto de casilleros estrat&eacute;gicamente ubicado
          seg&uacute;n las necesidades del Usuario, cuyo fin es resguardar
          bienes o mercanc&iacute;as, puestas con motivo de env&iacute;o o
          recepci&oacute;n y al cual se tiene acceso mediante una
          contrase&ntilde;a.
        </span>
      </p>
      <p className="c15">
        <span className="c6 c2">
          b) Carta factura a clientes por el uso de red/locker, privado o
          licencia
        </span>
        <span className="c1">: Documento que deber&aacute; </span>
      </p>
      <p className="c130">
        <span className="c1">
          contener los datos de facturaci&oacute;n necesarios, valor y
          descripci&oacute;n general del servicio prestado.
          {' '}
        </span>
      </p>
      <p className="c11">
        <span className="c6 c2">c) </span>
        <span className="c2">Lok</span>
        <span className="c1">: ClicknBox, S.A.P.I. de C.V. </span>
      </p>
      <p className="c30">
        <span className="c6 c2">d) Usuario</span>
        <span className="c1">: Parte que contrata con </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          a trav&eacute;s de su Sitio Web u otros medios, servicios
          {' '}
        </span>
      </p>
      <p className="c52">
        <span className="c1">
          que implican el uso de casilleros digitales dentro de la red
          p&uacute;blica de
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c1">. </span>
        <span className="c6 c2">e) Cliente</span>
        <span className="c1">
          : Persona moral cuya funci&oacute;n principal es fungir como
          intermediario entre
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          y el usuario final para contratar los servicios de uso de casilleros
          digitales ofrecidos por
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          tanto en red p&uacute;blica como privada mencionados en el Sitio Web.
          {' '}
        </span>
        <span className="c6 c2">f) C&oacute;digo</span>
        <span className="c1">
          : serie de n&uacute;meros y letras secretas que s&oacute;lo conoce el
          Usuario y a quien este se lo
          {' '}
        </span>
      </p>
      <p className="c52">
        <span className="c1">
          proporcione para tener acceso al Locker Inteligente.
          {' '}
        </span>
        <span className="c6 c2">g) Contrase&ntilde;a: </span>
        <span className="c1">
          Palabra, frase, signo o se&ntilde;al que solo conocen el Usuario y a
          las terceras personas
          {' '}
        </span>
      </p>
      <p className="c151">
        <span className="c1">
          que el lo comparta mismos que les permite tener acceso y hacer uso del
          locker inteligente.
          {' '}
        </span>
        <span className="c6 c2">h) Empresa de mensajer&iacute;a</span>
        <span className="c1">
          : Cualquier empresa que presta servicios de recolecci&oacute;n y
          entrega de
          {' '}
        </span>
      </p>
      <p className="c141">
        <span className="c1">
          env&iacute;os, paqueter&iacute;a o correo postal.
          {' '}
        </span>
        <span className="c6 c2">i) Mercanc&iacute;a</span>
        <span className="c1">
          : Art&iacute;culo puesto en el casillero digital para env&iacute;o o
          entrega del mismo.
          {' '}
        </span>
      </p>
      <p className="c115">
        <span className="c6 c2">j) El lugar de recolecci&oacute;n/entrega</span>
        <span className="c1">
          : Se refiere a la ubicaci&oacute;n del casillero digital contratado.
          {' '}
        </span>
      </p>
      <p className="c43 c132">
        <span className="c6 c2">Red de Box/Red de casilleros: </span>
        <span className="c1">
          Servicio que consiste en el Marketplace de Lockers Inteligentes
          disponibles para el usuario o cliente
          {' '}
        </span>
        <span className="c6 c2">. </span>
      </p>
      <p className="c113">
        <span className="c6 c2">k) Explosivos: </span>
        <span className="c1">
          Materiales con riesgo de explosi&oacute;n, proyecci&oacute;n o de
          incendio.
          {' '}
        </span>
      </p>
      <p className="c128">
        <span className="c6 c2">l) Gases: </span>
        <span className="c1">
          Sustancias comprimidas o licuadas, en soluci&oacute;n.
          {' '}
        </span>
      </p>
      <p className="c140">
        <span className="c6 c13">
          ACCESO Y CONTENIDO DEL &ldquo;SITIO WEB&rdquo;.
          {' '}
        </span>
      </p>
      <p className="c100">
        <span className="c6 c2">I. </span>
        <span className="c1">El equipo t&eacute;cnico de </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          trabaja para garantizar el acceso al Sitio Web las 24 (veinticuatro)
          horas del d&iacute;a. Si en alg&uacute;n momento no se tuviera acceso
          al mismo por temas ajenos a
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">, </span>
        <span className="c1">
          &eacute;ste no asumir&aacute; ning&uacute;n tipo de responsabilidad,
          aunque trabajar&aacute; en el restablecimiento lo antes posible. El
          acceso podr&aacute; ser suspendido temporalmente, sin previo aviso, en
          el caso de que (i) hubiera un fallo del sistema, (ii) por tareas de
          mantenimiento, reparaci&oacute;n o (iii) por cualquier otra
          raz&oacute;n fuera del control de
        </span>
        <span className="c2">Lok</span>
      </p>
      <p className="c69">
        <span className="c1">
          Usted puede acceder y ver el contenido del Sitio Web desde su
          computadora o desde cualquier otro dispositivo.
          {' '}
        </span>
      </p>
      <p className="c80">
        <span className="c6 c31">II</span>
        <span className="c54 c31">. </span>
        <span className="c1">
          Es responsabilidad de cualquier Usuario o Cliente utilizar el Sitio
          Web de acuerdo con la forma en la que fue dise&ntilde;ado; en este
          sentido, queda prohibida la utilizaci&oacute;n de cualquier tipo de
          software que automatice la interacci&oacute;n o descarga de los
          contenidos o servicios proporcionados a trav&eacute;s del Sitio Web.
          Adem&aacute;s, el Usuario y/o Cliente se compromete a utilizar la
          informaci&oacute;n, contenidos o servicios ofrecidos a trav&eacute;s
          del Sitio Web de manera l&iacute;cita, sin contravenir lo dispuesto en
          los presentes T&eacute;rminos y Condiciones, la moral o el orden
          p&uacute;blico, y se abstendr&aacute; de realizar cualquier acto que
          pueda suponer una afectaci&oacute;n a los derechos de terceros, o
          perjudique de alg&uacute;n modo el funcionamiento del Sitio Web.
        </span>
      </p>
      <p className="c125">
        <span className="c1">
          Asimismo, el Usuario y/o Cliente se compromete a proporcionar
          informaci&oacute;n l&iacute;cita y veraz en cualquier formulario
          solicitado, en los cuales el Usuario y/o Cliente tenga que
          proporcionar ciertos datos o informaci&oacute;n para el acceso a
          alg&uacute;n contenido, producto o servicio ofrecido por el propio
          Sitio Web. En todo caso, el Usuario y/o Cliente notificar&aacute; de
          forma inmediata a
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          acerca de cualquier hecho que permita suponer el uso indebido de la
          informaci&oacute;n registrada en dichos formularios, tales como, robo,
          extrav&iacute;o, o acceso no autorizado a cuentas y/o
          contrase&ntilde;as, con el fin de proceder a su inmediata
          cancelaci&oacute;n.
        </span>
      </p>
      <p className="c61">
        <span className="c6 c2">III</span>
        <span className="c1">
          . El s&oacute;lo acceso al Sitio Web no supone el establecimiento de
          ning&uacute;n tipo de relaci&oacute;n entre el
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">y el Usuario y/o Cliente. </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          se reserva el derecho de inhabilitar los accesos a aquellos Usuarios
          y/o Clientes que utilicen de forma inadecuada el portal o canales de
          comunicaci&oacute;n del Sitio Web.
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          procura que la informaci&oacute;n contenida en el Sitio Web sea
          correcta y est&eacute; actualizada, por lo que, en cualquier momento y
          sin previo aviso,
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">puede realizar cambios en su contenido. </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          se reserva el derecho de modificar o retirar servicios o descuentos
          del Sitio Web y modificar precios, introducir nuevos servicios y
          descuentos sin previo aviso.
          {' '}
        </span>
      </p>
      <p className="c86">
        <span className="c6 c2">IV. </span>
        <span className="c1">
          Est&aacute; prohibido hacer un uso inapropiado de la p&aacute;gina de
          internet de
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          incluyendo, de manera enunciativa m&aacute;s no limitativa, la
          pirater&iacute;a inform&aacute;tica.
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          cooperar&aacute; con cualquier autoridad que solicite u ordene
          localizar o revelar la identidad de cualquier persona que env&iacute;e
          o publique cualquier material que viole el contenido de esta
          disposici&oacute;n.
        </span>
      </p>
      <p className="c61">
        <span className="c1">
          En este sentido, cualquier distribuci&oacute;n, publicaci&oacute;n o
          explotaci&oacute;n comercial o promocional del Sitio Web, o de
          cualquiera de los contenidos y c&oacute;digos incluidos en el Sitio
          Web, est&aacute; estrictamente prohibida, a menos de que usted haya
          recibido el previo permiso expreso por escrito del personal autorizado
          de
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">. </span>
        <span className="c1">
          De no ser como est&aacute; expresamente permitido en el presente
          contrato, usted no puede descargar, informar, exponer, publicar,
          copiar, reproducir, distribuir, transmitir, modificar, ejecutar,
          difundir, transferir, crear trabajos derivados de lo realizado por
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          o de cualquier otra manera explotar cualquiera de los contenidos,
          c&oacute;digos, datos o servicios disponibles a trav&eacute;s del
          Sitio Web. Si usted hace otro uso del Sitio Web, o de los contenidos,
          c&oacute;digos, datos o materiales que ah&iacute; se encuentren o que
          est&eacute;n disponibles a trav&eacute;s del Sitio Web, a no ser como
          se ha estipulado anteriormente, usted puede violar las leyes de
          derechos de autor y otras leyes aplicables de los Estados Unidos
          Mexicanos (&quot;M&eacute;xico&quot;) y de otros pa&iacute;ses,
          as&iacute; como las leyes estatales aplicables, y puede ser sujeto a
          responsabilidad legal por dicho uso no autorizado.
        </span>
      </p>
      <p className="c40">
        <span className="c6 c2">V</span>
        <span className="c54 c2">. </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          se compromete a realizar todos los esfuerzos razonables para excluir
          virus incluidos en la p&aacute;gina web, pero no puede asegurar la
          exclusi&oacute;n total y no aceptar&aacute; responsabilidad legal por
          tales virus. Es obligaci&oacute;n del Usuario y/o Cliente, el adoptar
          las medidas apropiadas antes de descargar informaci&oacute;n del Sitio
          Web.
        </span>
      </p>
      <p className="c59">
        <span className="c6 c2">VI. </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          puede en cualquier momento y sin aviso previo, restringir el acceso
          del Usuario y/o Cliente al Sitio Web y/o negarse a mantener
          correspondencia con el Usuario y/o Cliente, cuando:
          {' '}
        </span>
      </p>
      <p className="c42">
        <span className="c6 c2">1. </span>
        <span className="c1">
          Exista un cambio normativo o legal que limite la capacidad de
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">para proporcionar acceso </span>
      </p>
      <p className="c74">
        <span className="c1">al Sitio Web </span>
        <span className="c6 c2">2. </span>
        <span className="c1">Exista alg&uacute;n evento fuera del control de </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          que impida ofrecer acceso al Sitio Web (de manera enunciativa,
          m&aacute;s no limitativa, dificultades t&eacute;cnicas, problemas de
          capacidad o problemas de comunicaci&oacute;n).
          {' '}
        </span>
        <span className="c6 c2">3. </span>
        <span className="c1">
          El Usuario y/o Cliente est&eacute; abusando del Sitio Web o su
          actuaci&oacute;n sea contraria a los T&eacute;rminos y
          {' '}
        </span>
      </p>
      <p className="c51">
        <span className="c1">Condiciones aqu&iacute; expuestos. </span>
        <span className="c6 c2">4. </span>
        <span className="c1">
          Por mandamiento judicial o de autoridad administrativa, nacional o
          extranjera.
          {' '}
        </span>
      </p>
      <p className="c144">
        <span className="c6 c2">VII. </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          no se hace responsable de cualquier p&eacute;rdida directa, indirecta
          o da&ntilde;os sufridos por cualquier persona a consecuencia o en
          relaci&oacute;n con el uso del Sitio Web, la imposibilidad de su uso,
          los sitios web vinculados a &eacute;sta y cualquier material publicado
          en ella, incluyendo, responsabilidades derivadas de:
        </span>
      </p>
      <p className="c62">
        <span className="c6 c2">a) </span>
        <span className="c1">P&eacute;rdida de ingresos o ganancias. </span>
      </p>
      <p className="c127">
        <span className="c6 c2">b) </span>
        <span className="c1">P&eacute;rdida de negocio. </span>
      </p>
      <p className="c71">
        <span className="c6 c2">c) </span>
        <span className="c1">P&eacute;rdida de beneficios o contratos. </span>
      </p>
      <p className="c88">
        <span className="c6 c2">d) </span>
        <span className="c1">P&eacute;rdida de ahorros anticipados. </span>
      </p>
      <p className="c12">
        <span className="c6 c2">e) </span>
        <span className="c1">P&eacute;rdida de datos. </span>
      </p>
      <p className="c98">
        <span className="c6 c2">f) </span>
        <span className="c1">
          Cualquier otra p&eacute;rdida o da&ntilde;o de cualquier tipo que
          pudiera surgir y que fuera causado por agravio
          {' '}
        </span>
      </p>
      <p className="c67">
        <span className="c1">
          (incluyendo negligencia), incumplimiento de contrato o de otro tipo.
          {' '}
        </span>
      </p>
      <p className="c103">
        <span className="c6 c13">
          POL&Iacute;TICA DE PRIVACIDAD Y PROTECCI&Oacute;N DE DATOS PERSONALES
          {' '}
        </span>
      </p>
      <p className="c48">
        <span className="c6 c31">I </span>
        <span className="c1">
          El acceso o utilizaci&oacute;n del Sitio Web y/o de los productos y
          servicios ofrecidos por
          {' '}
        </span>
        <span className="c17">Lok</span>
        <span className="c1">
          , confiere la condici&oacute;n de Usuario y/o Cliente, por lo que
          quedar&aacute; sujeto a los presentes T&eacute;rminos y Condiciones,
          as&iacute; como a sus ulteriores modificaciones, sin perjuicio de la
          aplicaci&oacute;n de la legislaci&oacute;n aplicable, por tanto, se
          tendr&aacute;n por aceptados desde el momento en el que se accede al
          Sitio Web y/o se contratan o utilizan los productos y servicios
          ofrecidos por
        </span>
        <span className="c17">Lok</span>
        <span className="c1">
          . Dada la relevancia de lo anterior, se recomienda al Usuario revisar
          las actualizaciones que se realicen a los presentes T&eacute;rminos y
          Condiciones.
          {' '}
        </span>
      </p>
      <p className="c3">
        <span className="c6 c2">II</span>
        <span className="c54 c2">. </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          no se hace responsable de la veracidad, exactitud, legalidad o
          moralidad de los contenidos realizados por terceras personas,
          f&iacute;sicas o morales a trav&eacute;s de la p&aacute;gina o Sitio
          Web, aplicaciones m&oacute;viles desarrolladas y/o a trav&eacute;s de
          la pantalla que se puedan ofrecer y/o generar hiperv&iacute;nculos o
          acceso a p&aacute;ginas Web y todo tipo de contenidos de terceras
          personas,
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          no controla, ni garantiza el contenido incluido en dichos sitios,
          tampoco se responsabiliza del funcionamiento o accesibilidad de las
          p&aacute;ginas Web vinculadas; ni sugiere, invita o recomienda la
          visita a las mismas, por lo que tampoco ser&aacute; responsable del
          resultado obtenido.
        </span>
      </p>
      <p className="c114">
        <span className="c1">El Usuario y/o Cliente acepta que </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          no es responsable de ning&uacute;n contenido, enlace asociado, recurso
          o servicio relacionado con el sitio de un tercero. Asimismo, el
          Usuario y/o Cliente acepta que
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          no ser&aacute; responsable de ninguna p&eacute;rdida o da&ntilde;o de
          cualquier tipo que se derive del uso que se realice de los contenidos
          de un tercero.
          {' '}
        </span>
      </p>
      <p className="c156">
        <span className="c1">
          El Usuario y/o Cliente acepta que cualquier relaci&oacute;n
          contractual o extracontractual que pueda formalizar o formalice con
          terceros contactos a trav&eacute;s del Sitio Web, aplicaciones y/o
          pantallas ser&aacute; ajena a
        </span>
        <span className="c2">Lok</span>
        <span className="c1">
          , a la que el Usuario y/o Cliente desde este momento exonera de toda
          responsabilidad sobre los da&ntilde;os o perjuicios ocasionados con
          motivo de sus negociaciones, conversaciones y/o relaciones
          contractuales o extracontractuales con los terceros que utilizan el
          Sitio Web aplicaciones y/o pantallas.
        </span>
      </p>
      <p className="c78">
        <span className="c6 c2">III</span>
        <span className="c54 c2">. </span>
        <span className="c1">
          Para poder realizar la contrataci&oacute;n de los servicios que ofrece
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c1">
          , el Usuario deber&aacute; proporcionar los datos solicitados por
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          incluyendo datos de identificaci&oacute;n personal y m&eacute;todos de
          pago , ya que posteriormente se realizar&aacute; el respectivo
          contrato para la prestaci&oacute;n de servicio. En el caso de
          Clientes, se presentar&aacute; una propuesta comercial en la cual se
          solicitar&aacute;n diversos datos de acuerdo con el modelo de negocio
          seleccionado ya que posteriormente se negociar&aacute;n las tarifas y
          la prestaci&oacute;n de los servicios.
        </span>
      </p>
      <p className="c150">
        <span className="c6 c2">IV</span>
        <span className="c54 c2">. </span>
        <span className="c1">
          Los datos proporcionados se tratar&aacute;n con base en las
          pol&iacute;ticas de privacidad vigentes y exigidas por la Ley Federal
          de Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los
          Particulares y su reglamento, as&iacute; como por el Instituto
          Nacional de Transparencia, Acceso a la Informaci&oacute;n y
          Protecci&oacute;n de Datos Personales, y se utilizar&aacute;n para
          informar al Usuario y/o Cliente de cambios importantes en los
          servicios, as&iacute; como nuevas promociones y ofertas especiales,
          salvo expresa comunicaci&oacute;n en contrario. Al proporcionar los
          datos, el Usuario y/o Cliente acepta recibir estos correos
          electr&oacute;nicos. Al utilizar el Sitio Web, el Usuario y/o Cliente
          ofrece su consentimiento a dicho tratamiento. La pol&iacute;tica de
          privacidad podr&aacute; ser consultada en el correspondiente apartado
          a trav&eacute;s de su Sitio Web.
        </span>
      </p>
      <p className="c135">
        <span className="c6 c13">
          ENLACES HACIA Y DESDE SITIOS WEB DE TERCEROS.
          {' '}
        </span>
      </p>
      <p className="c48">
        <span className="c6 c2">I. </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          a trav&eacute;s de su Sitio Web ofrece enlaces a p&aacute;ginas Web de
          terceros para la utilidad y conveniencia del Usuario y/o Cliente. Si
          utiliza estos v&iacute;nculos y el Usuario y/o Cliente abandona el
          Sitio Web,
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          no se hace responsable de su contenido o disponibilidad.
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          no promociona ni representa a ninguno de ellos ni cualquier material
          que encuentre en ellos, ni es responsable de las consecuencias de su
          uso. Si el Usuario y/o Cliente decide acceder a cualquiera de los
          sitios Web de terceros vinculados al Sitio Web lo har&aacute; bajo su
          entera responsabilidad.
        </span>
      </p>
      <p className="c157">
        <span className="c6 c2">II</span>
        <span className="c54 c2">. </span>
        <span className="c1">
          Si se desea crear un v&iacute;nculo del Sitio Web se podr&aacute;
          hacer &uacute;nicamente creando un v&iacute;nculo directo y siempre y
          cuando no se copie o replique la p&aacute;gina principal y sujeto a
          las siguientes condiciones:
        </span>
      </p>
      <p className="c49">
        <span className="c6 c2">a) </span>
        <span className="c1">
          No se podr&aacute; retirar, distorsionar o alterar el tama&ntilde;o o
          la apariencia de ning&uacute;n logo utilizado el Sitio
          {' '}
        </span>
      </p>
      <p className="c110">
        <span className="c1">Web. </span>
        <span className="c6 c2">b) </span>
        <span className="c1">
          No se podr&aacute; crear un marco o borde alrededor del logo del Sitio
          Web.
          {' '}
        </span>
        <span className="c6 c2">c) </span>
        <span className="c1">
          No se podr&aacute;, en ninguna circunstancia, promocionar otros
          servicios o productos que no sean
          {' '}
        </span>
      </p>
      <p className="c23">
        <span className="c1">exactamente los de </span>
        <span className="c2">Lok</span>
        <span className="c1">. </span>
        <span className="c6 c2">d) </span>
        <span className="c1">No falsear ni manipular su relaci&oacute;n con </span>
        <span className="c2">Lok</span>
        <span className="c1">
          , ni presentar ning&uacute;n tipo de informaci&oacute;n falsa
          {' '}
        </span>
      </p>
      <p className="c118">
        <span className="c1">al respecto. </span>
      </p>
      <p className="c119">
        <span className="c6 c2">III </span>
        <span className="c1">
          Todo el contenido del Sitio, incluyendo, pero no limitando a textos,
          gr&aacute;ficos, fotograf&iacute;as, logotipos, audio, video, marcas,
          im&aacute;genes, bases de datos, as&iacute; como el dise&ntilde;o
          gr&aacute;fico, c&oacute;digo fuente y software, son de exclusiva
          propiedad de
        </span>
        <span className="c2">Lok</span>
        <span className="c1">, o de terceros, cuyos derechos reconoce </span>
        <span className="c2">Lok</span>
        <span className="c1">
          , y est&aacute;n sujetos a derechos de Propiedad Intelectual e
          Industrial protegidos por la Legislaci&oacute;n Nacional e
          Internacional aplicable.
          {' '}
        </span>
      </p>
      <p className="c111">
        <span className="c1">
          En virtud de lo anterior no se permite usar marcas de las que
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          sea propietaria (est&eacute;n registradas o no) y que se muestren en
          el Sitio Web, incluyendo todos sus subdominios, sin el permiso expreso
          y por escrito de
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c1">. </span>
      </p>
      <p className="c61">
        <span className="c1">El Sitio Web de </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          no alberga contenidos que puedan considerarse desagradables, ofensivos
          o controvertidos, que violen los derechos de propiedad intelectual u
          otros derechos de cualquier otra persona o que no cumplan con Leyes y
          Reglamentos.
        </span>
      </p>
      <p className="c121">
        <span className="c6 c13">
          PROTECCI&Oacute;N DEL NOMBRE DE DOMINIO Y USO DE MARCA
          {' '}
        </span>
      </p>
      <p className="c96">
        <span className="c6 c2">I. </span>
        <span className="c1">
          El Usuario y/o Cliente se compromete a no registrar ning&uacute;n
          nombre de dominio que incluya la palabra
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          o cualquier derivado de &eacute;sta, que permita deducir que dicha
          palabra tiene relaci&oacute;n con el Sitio Web o ponga a
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          en entredicho. El Usuario y/o Cliente acepta que no adquirir&aacute;
          la palabra
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          o cualquier variante de ella que incluya la palabra
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          o que pueda confundirse con ella en Google Adwords o en cualquier otro
          servicio similar de publicidad en Internet.
          {' '}
        </span>
      </p>
      <p className="c66">
        <span className="c6 c2">II. </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          se reserva el derecho a contactar con el Usuario
          {' '}
        </span>
        <span className="c6 c58">y/o Cliente </span>
        <span className="c1">
          por correo electr&oacute;nico para ofrecerle promociones y ofertas
          especiales. Si el Usuario y/o Cliente no desea recibir estos correos
          electr&oacute;nicos deber&aacute; enviar un email, con las
          indicaciones a
        </span>
        <span className="c28 c17">hola@</span>
        <span className="c17 c33">chazki</span>
        <span className="c17 c28">.com </span>
        <span className="c1">
          conforme a los procedimientos descritos en el Aviso de Privacidad.
          {' '}
        </span>
      </p>
      <p className="c0">
        <span className="c6 c13">
          SERVICIOS, RELACI&Oacute;N CONTRACTUAL Y PAGO.
          {' '}
        </span>
      </p>
      <p className="c60">
        <span className="c6 c2">I. </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          ofrece el uso de Box estrat&eacute;gicamente ubicado seg&uacute;n las
          necesidades del Usuario
          {' '}
        </span>
        <span className="c6 c58">y/o Cliente</span>
        <span className="c1">
          , con el objeto de recibir o enviar paquetes de formar r&aacute;pida y
          segura. Para el uso se proporcionar&aacute;n dos c&oacute;digos, QR y
          alfanum&eacute;rico con los cuales podr&aacute; hacer uso de este, y
          por el cual deber&aacute; pagar una tarifa que se fijar&aacute; al
          momento de la contrataci&oacute;n.
        </span>
      </p>
      <p className="c162">
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          cuenta con una red de lockers en ubicaciones estrat&eacute;gicas para
          cubrir las necesidades del Usuario y/o Cliente a fin de que la
          mercanc&iacute;a pueda ser recolectada y entregada en cada uno de
          ellos seg&uacute;n las indicaciones del Usuario y/o Cliente.
        </span>
      </p>
      <p className="c111">
        <span className="c1">
          Las ofertas publicadas en el Sitio Web, aplicaciones y/o pantallas no
          son vinculantes y est&aacute;n sujetas a variaciones hasta el mismo
          momento de la celebraci&oacute;n del Contrato. Las ofertas
          est&aacute;n dirigidas exclusivamente a las personas f&iacute;sicas y
          morales con plena capacidad para celebrar contratos.
        </span>
      </p>
      <p className="c40">
        <span className="c6 c2">II. </span>
        <span className="c1">
          La aceptaci&oacute;n de los presentes T&eacute;rminos y Condiciones de
          Uso entre las Partes se ajustar&aacute; a la forma y procedimiento
          establecido por
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c1">
          . Al momento de la contrataci&oacute;n del Box se definir&aacute;n los
          precios de acuerdo con el modelo de negocio, la disponibilidad,
          ubicaci&oacute;n del locker y tiempo de uso.
          {' '}
        </span>
      </p>
      <p className="c133">
        <span className="c6 c2">III</span>
        <span className="c2 c54">. </span>
        <span className="c1">Toda comunicaci&oacute;n entre </span>
        <span className="c2">Lok</span>
        <span className="c2 c6">&nbsp;</span>
        <span className="c1">
          y el Usuario se har&aacute; en espa&ntilde;ol y/o ingl&eacute;s y por
          correo electr&oacute;nico, salvo que ambas Partes acuerden hacerlos
          por correo, fax, tel&eacute;fono o chat en vivo.
          {' '}
        </span>
      </p>
      <p className="c44">
        <span className="c6 c2">IV</span>
        <span className="c54 c2">. </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          no tiene el uso directo del Box contratado, por lo que una vez
          contratado &eacute;ste el uso del box y los c&oacute;digos ser&aacute;
          de exclusiva responsabilidad del Usuario y/o Cliente. En caso de
          p&eacute;rdida, destrucci&oacute;n, alteraci&oacute;n o
          afectaci&oacute;n a la mercanc&iacute;a resguardad en el Box con
          motivo del uso un incorrecto uso, ser&aacute; responsabilidad del
          Usuario y/o Cliente.
        </span>
      </p>
      <p className="c32">
        <span className="c6 c13">OBLIGACIONES DE </span>
        <span className="c13">Lok</span>
        <span className="c6 c13">&nbsp;CON RESPECTO AL USUARIO Y/O CLIENTE </span>
      </p>
      <p className="c77">
        <span className="c6 c2">I. </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          se compromete a cumplir las siguientes condiciones:
          {' '}
        </span>
      </p>
      <p className="c155">
        <span className="c6 c2">a) </span>
        <span className="c1">
          Mantendr&aacute; el Box en funcionamiento &oacute;ptimo, conservando
          los mecanismos de
          {' '}
        </span>
      </p>
      <p className="c154">
        <span className="c1">seguridad f&iacute;sicos y digitales. </span>
      </p>
      <p className="c5">
        <span className="c6 c2">b) </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          nunca entrar&aacute; en contacto directo con la Mercanc&iacute;a, y
          solo se encargar&aacute; de
          {' '}
        </span>
      </p>
      <p className="c143">
        <span className="c1">que el Box opere con normalidad. </span>
      </p>
      <p className="c91">
        <span className="c6 c2">c) </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          se reserva el derecho de modificar o retirar servicios o descuentos de
          su Sitio Web y modificar precios, introducir nuevos servicios y
          descuentos sin previo aviso.
          {' '}
        </span>
      </p>
      <p className="c21">
        <span className="c6 c2">d) </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          se compromete a atender todas las reclamaciones/consultas del Usuario
          y/o Cliente siempre que se realicen por escrito o v&iacute;a
          telef&oacute;nica a trav&eacute;s de la cuenta de e- mail y el
          tel&eacute;fono proporcionado para reclamaciones/consultas y
          aclaraciones.
        </span>
      </p>
      <p className="c106">
        <span className="c6 c2">e) </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          no es responsable de la Mercanc&iacute;a que es resguardada en el Box,
          ni de su
          {' '}
        </span>
      </p>
      <p className="c160">
        <span className="c1">
          manejo una vez que es extra&iacute;do de &eacute;ste.
          {' '}
        </span>
      </p>
      <p className="c152">
        <span className="c6 c2">f) </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          no se responsabiliza del manejo que hagan terceros con los cuales el
          Usuario
          {' '}
        </span>
      </p>
      <p className="c46">
        <span className="c1">haya compartido su contrase&ntilde;a y clave. </span>
      </p>
      <p className="c93">
        <span className="c6 c13">OBLIGACIONES DEL USUARIO </span>
      </p>
      <p className="c161">
        <span className="c6 c2">I. </span>
        <span className="c1">
          El Usuario asume y garantiza las siguientes condiciones:
          {' '}
        </span>
      </p>
      <p className="c24">
        <span className="c6 c2">a) </span>
        <span className="c1">
          Todas las Mercanc&iacute;as u objetos resguardados en el Box
          deber&aacute;n estar debidamente empaquetados y protegidos para no
          afectar el mecanismo del Box, en caso de que una mensajer&iacute;a
          tenga acceso al Box ser&aacute; responsabilidad del Usuario y/o
          Clientes que la mercanc&iacute;a cumpla con el embalaje y condiciones
          del transportista.
        </span>
        <span className="c6 c2">b) </span>
        <span className="c1">
          El usuario ser&aacute; responsable del uso y resguardo de los
          c&oacute;digos y claves proporcionadas.
          {' '}
        </span>
      </p>
      <p className="c92">
        <span className="c1">
          Ser&aacute; su responsabilidad en caso de que la comparta con
          terceros.
          {' '}
        </span>
        <span className="c6 c2">c) </span>
        <span className="c1">
          Los art&iacute;culos que no se entreguen en una caja o que no
          est&eacute;n adecuadamente embalados
          {' '}
        </span>
      </p>
      <p className="c92">
        <span className="c1">
          queda excluidos de los servicios y responsabilidades ofrecidos en la
          Red de Box.
          {' '}
        </span>
        <span className="c6 c2">d) </span>
        <span className="c1">
          Todos los datos que proporciona el Usuario y/o Cliente (incluso por
          medios electr&oacute;nicos) en relaci&oacute;n con la solicitud y
          contrataci&oacute;n, etiquetado o facturaci&oacute;n deber&aacute;n
          ser exactos, completos, suficientes y coincidentes con los aportados
          en la contrataci&oacute;n.
        </span>
      </p>
      <p className="c166">
        <span className="c6 c2">e) </span>
        <span className="c1">
          S&iacute; la Mercanc&iacute;a cumple y se ajusta a todas las
          obligaciones legales y reglamentos sobre el transporte de
          mercanc&iacute;as por carretera, mar y aire, cualquier sanci&oacute;n
          administrativa, civil, fiscal o penal que pudiesen derivar por el
          incumplimiento de este punto o si la mercanc&iacute;a fuera
          considerada ilegal, bien en el pa&iacute;s de origen, tr&aacute;nsito
          o destino, ser&aacute; repercutida &iacute;ntegramente al Usuario y/o
          Cliente.
        </span>
      </p>
      <p className="c16">
        <span className="c6 c2">f) </span>
        <span className="c1">
          El Usuario y/o Cliente acepta que, en el caso de no cumplir con las
          condiciones de esta secci&oacute;n, se exonerar&aacute; de toda
          responsabilidad sobre cualquier gasto, coste, p&eacute;rdida o
          da&ntilde;o que pudiera ocasionarse a
        </span>
        <span className="c2">Lok</span>
        <span className="c1">
          . En este caso el Usuario y/o Cliente asumir&iacute;a la
          responsabilidad sobre cualquier da&ntilde;o, gasto o perjuicio que
          pudiera ocasionar el no cumplimiento de las condiciones de esta
          secci&oacute;n a
        </span>
        <span className="c2">Lok</span>
        <span className="c1">. </span>
      </p>
      <p className="c97">
        <span className="c6 c2">g) </span>
        <span className="c1">
          El Usuario y/o Cliente se har&aacute; responsable de todos los gastos
          relacionados con el
          {' '}
        </span>
      </p>
      <p className="c136">
        <span className="c1">
          transporte, env&iacute;o y recolecci&oacute;n de mercanc&iacute;as.
          {' '}
        </span>
      </p>
      <p className="c14">
        <span className="c6 c2">h) </span>
        <span className="c1">
          Es responsabilidad del Usuario y/o Cliente cubrir los costes de
          despacho e impuestos aduaneros que deber&aacute;n asumir el
          dep&oacute;sito de la mercanc&iacute;a en un Box localizado en el
          extranjero, a fin de evitar incidencias, dep&oacute;sitos o abandonos
          aduaneros.
        </span>
      </p>
      <p className="c117">
        <span className="c6 c2">i) </span>
        <span className="c1">
          Es responsabilidad del Usuario aportar la documentaci&oacute;n
          necesaria para la tramitaci&oacute;n
          {' '}
        </span>
      </p>
      <p className="c124">
        <span className="c1">
          de siniestros de su mercanc&iacute;a (roturas, robos,
          extrav&iacute;os, accidentes etc.).
          {' '}
        </span>
      </p>
      <p className="c41">
        <span className="c6 c13">FUERZA MAYOR O CASOS FORTUITO </span>
      </p>
      <p className="c108">
        <span className="c1">
          Pueden darse retrasos, da&ntilde;os en mercanc&iacute;as o fallas en
          el servicio debido a los siguientes motivos y respecto de los cuales
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">no tendr&aacute; responsabilidad alguna: </span>
      </p>
      <p className="c16">
        <span className="c6 c2">a) </span>
        <span className="c1">
          Guerra, guerra civil, invasi&oacute;n, acto de enemigo extranjero,
          hostilidades (ya sea guerra declarada o no), rebeli&oacute;n,
          revoluci&oacute;n, insurrecci&oacute;n o usurpaci&oacute;n de poder,
          saqueo, o pillaje. Radiaciones ionizantes o contaminaci&oacute;n por
          radioactividad. Propiedades radiactivas, t&oacute;xicas, explosivas,
          peligrosas u otras de cualquier componente nuclear explosivo, y/o
          ondas de presi&oacute;n causadas por aviones y otros aparatos
          a&eacute;reos que viajan a velocidades s&oacute;nicas o
          supers&oacute;nicas.
        </span>
      </p>
      <p className="c134">
        <span className="c6 c2">b) </span>
        <span className="c1">
          Huelgas, manifestaciones, celebraciones de eventos de car&aacute;cter
          municipal, estatal o
          {' '}
        </span>
      </p>
      <p className="c50">
        <span className="c1">
          federal en la zona de recolecci&oacute;n, tr&aacute;nsito o entrega.
          {' '}
        </span>
      </p>
      <p className="c26">
        <span className="c6 c2">c) </span>
        <span className="c1">
          Cat&aacute;strofes naturales y condiciones meteorol&oacute;gicas
          adversas que impidan la correcta
          {' '}
        </span>
      </p>
      <p className="c9">
        <span className="c1">
          circulaci&oacute;n de los veh&iacute;culos encomendados al transporte
          de la Mercanc&iacute;a.
          {' '}
        </span>
      </p>
      <p className="c149">
        <span className="c6 c2">d) </span>
        <span className="c1">
          Ausencia, insuficiencia o incorrecto embalaje de la Mercanc&iacute;a.
          {' '}
        </span>
      </p>
      <p className="c38">
        <span className="c6 c2">e) </span>
        <span className="c1">
          Falta de informaci&oacute;n para la realizaci&oacute;n de la entrega o
          recolecci&oacute;n.
          {' '}
        </span>
      </p>
      <p className="c26">
        <span className="c6 c2">f) </span>
        <span className="c1">
          Los env&iacute;os hacia y desde &aacute;reas remotas, tanto a nivel
          nacional como internacional,
          {' '}
        </span>
      </p>
      <p className="c57">
        <span className="c1">
          pueden estar sujetos a demoras y a retiradas/baja del servicio.
          {' '}
        </span>
      </p>
      <p className="c117">
        <span className="c6 c2">g) </span>
        <span className="c1">
          Retrasos en los servicios de aduanas o zonas fronterizas con motivo de
          las actividades
          {' '}
        </span>
      </p>
      <p className="c22">
        <span className="c1">de las autoridades nacionales o extranjeras. </span>
      </p>
      <p className="c138">
        <span className="c6 c2">h) </span>
        <span className="c1">
          Por retenci&oacute;n dela mercanc&iacute;a o paquete de env&iacute;o
          de parte de una autoridad.
          {' '}
        </span>
      </p>
      <p className="c43">
        <span className="c1">
          Los retrasos en los servicios producidos por cualquiera de los puntos
          detallados no podr&aacute;n ser objeto de reclamaci&oacute;n,
          compensaci&oacute;n o devoluci&oacute;n de importe.
          {' '}
        </span>
      </p>
      <p className="c64">
        <span className="c6 c13">ART&Iacute;CULOS PROHIBIDOS </span>
      </p>
      <p className="c165">
        <span className="c1">
          Est&aacute; prohibido utilizar el &ldquo;Box&rdquo; para resguardar
          cualquier Mercanc&iacute;a il&iacute;cita descritas en los siguientes
          incisos:
          {' '}
        </span>
      </p>
      <p className="c129">
        <span className="c6 c2">a) </span>
        <span className="c1">
          Materiales y residuos peligrosos, armas de fuego, objetos
          punzocortantes (machetes, cuchillos), drogas, psicotr&oacute;picos y
          estupefacientes sin receta m&eacute;dica, &oacute;rganos, tejidos etc,
          pieles y cuero de animales, cualquier art&iacute;culo iegal,
          falsificados, contrabando o prohib&iacute;dos, explosivos, gases,
          l&iacute;quidos inflamables, s&oacute;lidos inflamables, sustancias
          comburentes y per&oacute;xidos org&aacute;nicos, sustancias
          t&oacute;xicas e infecciosas, sustanc&iacute;as corrosivas, sustancias
          y objetos peligrosos varios, que pone en peligro tanto el
          &ldquo;BOX&rdquo;, como el orden social.
        </span>
        <span className="c6 c31">b) </span>
        <span className="c1">
          Que constituya o fomente una conducta que ser&iacute;a considerada
          como una ofensa criminal, que diera lugar a responsabilidad civil, que
          sea ilegal o infrinja los derechos de terceros, en cualquier
          pa&iacute;s del mundo.
        </span>
        <span className="c6 c31">c) </span>
        <span className="c1">
          Que sea t&eacute;cnicamente da&ntilde;ino (incluyendo, sin
          limitaci&oacute;n, virus inform&aacute;ticos, bombas l&oacute;gicas,
          {' '}
        </span>
      </p>
      <p className="c158">
        <span className="c1">
          troyanos, gusanos, datos corruptos o software malicioso).
          {' '}
        </span>
        <span className="c6 c31">d) </span>
        <span className="c1">
          Que afecte el inter&eacute;s social, pol&iacute;ticas p&uacute;blicas,
          actividades gubernamentales, exhiba a
          {' '}
        </span>
      </p>
      <p className="c34">
        <span className="c1">
          servidores p&uacute;blicos, o cuestione, critique o difame cualquier
          entidad o instituci&oacute;n p&uacute;blica.
          {' '}
        </span>
      </p>
      <p className="c99">
        <span className="c6 c31">e) </span>
        <span className="c1">
          Que critique, cuestione, ponga en duda o reclame cualquier actividad
          gubernamental, social,
          {' '}
        </span>
      </p>
      <p className="c146">
        <span className="c1">
          econ&oacute;mica o cultural de un gobierno extranjero.
          {' '}
        </span>
      </p>
      <p className="c116">
        <span className="c1">
          Independientemente de las pol&iacute;ticas de env&iacute;o y de
          productos prohibidos de cada empresa de Mensajer&iacute;a que el
          Usuario y/o Cliente elija para realizar su env&iacute;o,
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          bajo ninguna condici&oacute;n acepta que se env&iacute;e los
          siguientes art&iacute;culos prohibidos.
          {' '}
        </span>
      </p>
      <p className="c55">
        <span className="c1">
          &bull; Mercanc&iacute;as prohibidas para su transportaci&oacute;n.
          {' '}
        </span>
      </p>
      <p className="c53">
        <span className="c1">
          &bull; Mercanc&iacute;as restringidas para su transportaci&oacute;n.
          {' '}
        </span>
      </p>
      <p className="c37">
        <span className="c1">&bull; Animales vivos. </span>
      </p>
      <p className="c18">
        <span className="c1">&bull; Bebidas alcoh&oacute;licas. </span>
      </p>
      <p className="c7">
        <span className="c1">
          &bull; Lingotes, dinero en efectivo, instrumentos negociables al
          portador.
          {' '}
        </span>
      </p>
      <p className="c126">
        <span className="c1">
          &bull; Animales, vegetales, plantas y sus productos que est&eacute;n
          en peligro de extinci&oacute;n.
          {' '}
        </span>
      </p>
      <p className="c65">
        <span className="c1">
          &bull; Productos o materiales piratas o falsificados.
          {' '}
        </span>
      </p>
      <p className="c90">
        <span className="c1">
          &bull; Antig&uuml;edades u obras de arte, art&iacute;culos de una
          colecci&oacute;n.
          {' '}
        </span>
      </p>
      <p className="c87">
        <span className="c1">&bull; Materiales inflamables. </span>
      </p>
      <p className="c8">
        <span className="c1">
          &bull; Medicamentos y productos farmac&eacute;uticos.
          {' '}
        </span>
      </p>
      <p className="c47">
        <span className="c1">
          &bull; Drogas, narc&oacute;ticos o similares que la Ley marca como
          ilegales.
          {' '}
        </span>
      </p>
      <p className="c83">
        <span className="c1">&bull; Muestras m&eacute;dicas. </span>
      </p>
      <p className="c79">
        <span className="c1">
          &bull; Armas de fuego, partes de estas, armas blancas y municiones,
          equipo militar.
          {' '}
        </span>
      </p>
      <p className="c81">
        <span className="c1">&bull; Art&iacute;culos perecederos. </span>
      </p>
      <p className="c148">
        <span className="c1">&bull; Restos humanos (incluyendo cenizas). </span>
      </p>
      <p className="c167">
        <span className="c1">
          &bull; Diamantes industriales, carbones, metales preciosos y piedras,
          relojes y joyer&iacute;a.
          {' '}
        </span>
      </p>
      <p className="c105">
        <span className="c1">&bull; Pornograf&iacute;a. </span>
      </p>
      <p className="c164">
        <span className="c1">
          &bull; Objeto prohibido por cualquier ley o gobierno federal, estatal
          o local.
          {' '}
        </span>
      </p>
      <p className="c68">
        <span className="c1">
          Se recomienda comprobar que se puede transportar la Mercanc&iacute;a
          antes de realizar su pedido, consultando la lista de Art&iacute;culos
          Prohibidos prevista en el presente documento de T&eacute;rminos y
          Condiciones. No se aceptar&aacute;n reclamaciones por p&eacute;rdida o
          da&ntilde;os de art&iacute;culos que pertenezcan a la lista de
          Art&iacute;culos Prohibidos. Quedar&aacute; anulada cualquier
          compensaci&oacute;n adicional para art&iacute;culos que pertenezcan a
          esta lista. Es responsabilidad del Usuario comprobar, antes de
          realizar el Env&iacute;o, que la Mercanc&iacute;a no est&aacute;
          incluido en las listas de Art&iacute;culos Prohibidos.
        </span>
      </p>
      <p className="c147">
        <span className="c1">
          Se proh&iacute;be introducir en el Box art&iacute;culos de la lista de
          Art&iacute;culos Prohibidos anteriormente T&eacute;rminos y
          Condiciones de uso a trav&eacute;s de cualquiera de los servicios de
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c1">. En caso de no respetar lo anterior, </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          podr&aacute; rescindir el contrato sin responsabilidad y el Usuario
          y/o Cliente tendr&aacute; que asumir las consecuencias
          econ&oacute;micas, incluso legales por ello, sin posibilidad de
          reclamaci&oacute;n de cobertura, incluida o adicional. No se
          admitir&aacute; ning&uacute;n tipo de reclamaci&oacute;n por retrasos
          en las entregas o recolecciones o cualquier otro tipo, sin excluir al
          Usuario y/o Cliente de responsabilidad de tipo civil, penal, fiscal o
          administrativa que derive del env&iacute;o de Art&iacute;culos
          Prohibidos conforme a los T&eacute;rminos y Condiciones.
        </span>
      </p>
      <p className="c25">
        <span className="c6 c13">CARGOS ADICIONALES AL SERVICIO </span>
      </p>
      <p className="c84">
        <span className="c1">El Usuario y/o Cliente acepta que </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          est&aacute; autorizada a cargar y cobrar los recargos
          autom&aacute;ticamente. Si no fuera posible realizar el pago con el
          m&eacute;todo de pago original,
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">utilizar&aacute; </span>
      </p>
      <p className="c86">
        <span className="c1">
          todas las medidas posibles, incluidas las v&iacute;as legales, para
          cobrar los cargos pendientes por parte del Usuario y/o Cliente. Todos
          los cargos adicionales ser&aacute;n facturados y enviados al correo
          electr&oacute;nico que hubiera registrado el Usuario y/o Cliente al
          momento de realizar el env&iacute;o y deber&aacute; pagar a
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          en un plazo m&aacute;ximo de 48 (cuarenta y ocho) horas despu&eacute;s
          de enviada la factura.
          {' '}
        </span>
      </p>
      <p className="c45">
        <span className="c6 c13">GESTI&Oacute;N DE RECLAMACIONES. </span>
      </p>
      <p className="c94">
        <span className="c6 c73">E</span>
        <span className="c1">
          n caso de da&ntilde;o o p&eacute;rdida de la Mercanc&iacute;a
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          s&oacute;lo ser&aacute; responsable por los fallos o aver&iacute;as en
          el Box, siempre y cuando no hayan derivado de un manejo inadecuado por
          parte del Usuario y/o Cliente.
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          no ser&aacute; responsable de reclamaciones posteriores por
          p&eacute;rdida de beneficios, problemas administrativos,
          frustraci&oacute;n o cualquier p&eacute;rdida o da&ntilde;o en
          relaci&oacute;n con una incidencia del servicio que se
          solicit&oacute;. Quedan fuera de compensaci&oacute;n las
          p&eacute;rdidas, da&ntilde;os y la devoluci&oacute;n de dinero en las
          siguientes circunstancias:
        </span>
        <span className="c10">a) </span>
        <span className="c39">Ausencia de embalaje o embalaje insuficiente. </span>
      </p>
      <p className="c122">
        <span className="c6 c2">b) </span>
        <span className="c1">Errores de etiquetado. </span>
      </p>
      <p className="c76">
        <span className="c6 c2">c) </span>
        <span className="c1">
          Env&iacute;o de art&iacute;culos que pertenezcan a la lista de
          Art&iacute;culos Prohibidos.
          {' '}
        </span>
      </p>
      <p className="c153">
        <span className="c6 c2">d) </span>
        <span className="c1">
          Fuerza mayor (desastres naturales, consecuencias de guerras, y en
          general todas las
          {' '}
        </span>
      </p>
      <p className="c101">
        <span className="c1">
          incidencias citadas en el punto 14) o Caso Fortuito.
          {' '}
        </span>
      </p>
      <p className="c109">
        <span className="c6 c13">
          FORMATO Y PLAZOS DE RECLAMACI&Oacute;N POR DA&Ntilde;OS O
          P&Eacute;RDIDAS
          {' '}
        </span>
      </p>
      <p className="c75">
        <span className="c6 c2">1. </span>
        <span className="c1">
          S&oacute;lo el Usuario y/o Cliente que contrat&oacute; el Box
          est&aacute; autorizado a presentar reclamaci&oacute;n y s&oacute;lo se
          indemnizar&aacute; en caso de proceder a la persona f&iacute;sica o
          moral que contrat&oacute; el servicio con base en los datos que
          proporcion&oacute; en el sistema.
        </span>
      </p>
      <p className="c63">
        <span className="c6 c2">2. </span>
        <span className="c1">
          Todas las reclamaciones por p&eacute;rdida deber&aacute;n efectuarse
          dentro de los siguientes cinco d&iacute;as que se
          {' '}
        </span>
      </p>
      <p className="c123">
        <span className="c1">
          verific&oacute; el siniestro. No se aceptar&aacute;n las reclamaciones
          recibidas posteriormente.
          {' '}
        </span>
      </p>
      <p className="c72">
        <span className="c6 c2">3. </span>
        <span className="c1">
          Toda reclamaci&oacute;n deber&aacute; realizarse por escrito y al
          correo electr&oacute;nico
          {' '}
        </span>
        <span className="c28 c17">hola@</span>
        <span className="c33 c17">chazki</span>
        <span className="c28 c17">.com</span>
        <span className="c1">
          . Una vez recibida la reclamaci&oacute;n se iniciar&aacute; una
          investigaci&oacute;n para determinar las causas del fallo y determinar
          si
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">fue responsable de ello. De ser procedente </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          indemnizar&aacute; por la p&eacute;rdida de la mercanc&iacute;a,
          previo acreditamiento de la naturaleza de &eacute;sta y hasta por un
          l&iacute;mite del monto del servicio contratado.
          {' '}
        </span>
      </p>
      <p className="c40">
        <span className="c1">
          Seguro incluido y compensaci&oacute;n adicional. Cualquier tipo de
          seguro o compensaci&oacute;n adicional que se requiera sobre la
          mercanc&iacute;a deber&aacute; ser contratado por el Usuario y/o
          Cliente.
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          no proporciona ning&uacute;n tipo de seguro sobre la mercanc&iacute;a.
          {' '}
        </span>
      </p>
      <p className="c112">
        <span className="c1">El personal de </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          ofrecer&aacute; respuesta a todas y cada una de las reclamaciones por
          incumplimiento de servicio, bas&aacute;ndose en el servicio contratado
          por el Usuario, y las circunstancias que han producido la fallo en el
          Box.
        </span>
      </p>
      <p className="c131">
        <span className="c6 c13">FACTURACI&Oacute;N APLAZADA </span>
      </p>
      <p className="c159">
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          pone a disposici&oacute;n de cualquier persona f&iacute;sica o moral,
          la opci&oacute;n de domiciliar el pago agrupado de sus servicios. Para
          ello deber&aacute;n de contactar con
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          y remitir la documentaci&oacute;n que &eacute;sta le indique como
          necesaria para el alta como Cuenta Aplazada. Una vez aprobado por el
          departamento correspondiente de
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c1">
          , el Usuario y/o Cliente tendr&aacute; acceso a la nueva forma de
          pago.
          {' '}
        </span>
      </p>
      <p className="c107">
        <span className="c1">
          El Usuario y/o Cliente con pago aplazado se compromete a generar una
          facturaci&oacute;n m&iacute;nima a acordar con
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c1">
          , por lo que en el supuesto de que una vez cerrado el ciclo de
          facturaci&oacute;n, no hubiera alcanzado
          {' '}
        </span>
      </p>
      <p className="c86">
        <span className="c1">dicha cantidad </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">emitir&aacute; el recibo por dicho importe. </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          genera 2 (dos) ciclos de facturaci&oacute;n mensuales, cuya fecha de
          cierre se realiza el 15 (quince) y &uacute;ltimo d&iacute;a de cada
          mes, generando recibo domiciliado al d&iacute;a siguiente h&aacute;bil
          a la emisi&oacute;n de la factura.
        </span>
      </p>
      <p className="c4">
        <span className="c6 c13">FACTURACI&Oacute;N </span>
      </p>
      <p className="c85">
        <span className="c1">
          Cualquier Usuario que solicite los servicios de Box en el Sitio Web
          tendr&aacute; 3 (tres) d&iacute;as h&aacute;biles despu&eacute;s de
          realizar su pago para solicitar su factura correspondiente.
          Despu&eacute;s de este plazo no podr&aacute; generarse factura alguna
          sin responsabilidad para
        </span>
        <span className="c2">Lok</span>
        <span className="c1">
          . A cada Cliente se le entregar&aacute; una factura mensual o conforme
          acordado en contrato.
          {' '}
        </span>
      </p>
      <p className="c35">
        <span className="c6 c13">LEGISLACI&Oacute;N APLICABLE </span>
      </p>
      <p className="c163">
        <span className="c1">
          Los presentes T&eacute;rminos y Condiciones, as&iacute; como cualquier
          relaci&oacute;n entre el Usuario y
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          se regir&aacute;n por la Legislaci&oacute;n Mexicana. Para cualquier
          litigio derivado de la existencia o contenido del presente documento o
          de las relaciones entre el Usuario y/o Cliente y
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c1">
          , las partes se someten a la jurisdicci&oacute;n de los tribunales
          competentes en la Ciudad de M&eacute;xico, con renuncia irrevocable
          por parte de cada una de ellas a cualquier otro fuero que pudiere
          corresponderles por raz&oacute;n de sus domicilios presentes o futuros
          o por cualquier otra causa.
        </span>
      </p>
      <p className="c104">
        <span className="c6 c27">DIVISIBILIDAD DE CL&Aacute;USULAS </span>
      </p>
      <p className="c60">
        <span className="c1">
          En la medida en que sea permitido por la Ley, si cualesquiera de las
          disposiciones de estos T&eacute;rminos y Condiciones es o llega a ser
          inexistente, nula, inv&aacute;lida o inexigible en alguna
          jurisdicci&oacute;n, ser&aacute; considerada as&iacute; exclusivamente
          con respecto a dicha jurisdicci&oacute;n y exclusivamente en la
          medida, en su caso, de la nulidad, invalidez o inexigibilidad, sin que
          ello afecte al resto de las disposiciones de estos T&eacute;rminos y
          Condiciones ni la validez o exigibilidad de dicho resto de las
          disposiciones en cualquier otra jurisdicci&oacute;n
        </span>
        <span className="c17 c19">. </span>
      </p>
      <p className="c82">
        <span className="c6 c13">GARANT&Iacute;AS ESTATUTARIAS </span>
      </p>
      <p className="c145">
        <span className="c1">
          Estos T&eacute;rminos y Condiciones son adicionales a sus derechos
          legales como consumidor, que no se ven afectados
        </span>
        <span className="c6 c73">. </span>
        <span className="c6 c13">
          ACEPTACI&Oacute;N DE LOS T&Eacute;RMINOS Y CONDICIONES
          {' '}
        </span>
      </p>
      <p className="c89">
        <span className="c1">Al momento de contratar los servicios de </span>
        <span className="c2">Lok</span>
        <span className="c6 c2">&nbsp;</span>
        <span className="c1">
          usted acepta en su totalidad los T&eacute;rminos y Condiciones del uso
          de p&aacute;gina y de servicio. En caso de no estar conforme no
          podr&aacute; continuar la relaci&oacute;n contractual con
          {' '}
        </span>
        <span className="c2">Lok</span>
        <span className="c1">, lo cual deber&aacute; hacer saber de </span>
        <span className="c2">Lok</span>
        <span className="c1">, por escrito al correo electr&oacute;nico </span>
        <span className="c28 c17">hola@</span>
        <span className="c33 c17">chazki</span>
        <span className="c28 c17">.com</span>
        <span className="c1">
          , a fin de proceder a concluir la relaci&oacute;n y que cada una de
          las partes cumpla con las obligaciones pendientes de cumplir.
          {' '}
        </span>
      </p>
      <p className="c20">
        <span className="c1">
          El presente documento fue actualizado el 23 (primero) de diciembre de
          2019 (dos mil diecinueve).
          {' '}
        </span>
      </p>
    </div>
  );
}

export default TermsAndConditions;
