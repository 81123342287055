import { formatComponents } from './dates';

const getEntry = (arr, status) => arr.filter((event) => event.status === status).pop();

export function getDeliveryDate(reservation) {
  const deliveryEvent = getEntry(reservation.eventHistory, 'delivered');

  return formatComponents(deliveryEvent.date);
}

export function getPickupDate(reservation) {
  const pickupEvent = getEntry(reservation.eventHistory, 'picked_up');

  return pickupEvent ? formatComponents(pickupEvent.date) : null;
}

export function getSizeStr(size = '') {
  switch (size.toLowerCase()) {
    case 's':
      return 'chico';
    case 'm':
      return 'mediano';
    case 'l':
      return 'grande';
    case 'xl':
      return 'extra grande';
    default:
      return '';
  }
}
