// eslint-disable-next-line import/prefer-default-export
export const getLocations = {
  locations: [{
    id: '5fc94540644e9b60993b2d0e',
    timezone: 'America/Chicago',
    internal_name: 'Pruebas Migracion',
    address: {
      parking: false, street: 'Blvd Benito Juarez', number: '49', town: 'lazaro cardenas', zip_code: '54900', city: 'Ciudad de México', country: 'Mexico',
    },
    coords: { latitude: 19.633784, longitude: -99.154683 },
    service_days: [],
    uuid: '0085',
    boxes: [{ occupied: 7, size: 's', total: 11 }, { occupied: 4, size: 'm', total: 4 }, { occupied: 0, size: 'l', total: 2 }],
    public_boxes: 0,
  }, {
    id: '5e7138d01168b4652eb94935',
    timezone: 'America/Chicago',
    uuid: '0033',
    internal_name: 'QA1 - Lok',
    address: {
      street: 'Ejército Nacional', number: '381', town: 'Granada', zip_code: '11520', city: 'Ciudad de México', state: 'Ciudad de México', country: 'México', parking: false,
    },
    service_days: [],
    coords: { latitude: 19.4380595, longitude: -99.1885031 },
    boxes: [{ occupied: 7, size: 's', total: 7 }, { occupied: 2, size: 'm', total: 2 }, { occupied: 1, size: 'l', total: 1 }],
    public_boxes: 1,
  }, {
    id: '5e71394c1168b4652eb94937',
    timezone: 'America/Chicago',
    uuid: '0034',
    internal_name: 'QA2 - Lok',
    address: {
      street: 'Ejército Nacional', number: '381', town: 'Granada', zip_code: '11520', city: 'Ciudad de México', state: 'Ciudad de México', country: 'México', parking: false,
    },
    service_days: [],
    coords: { latitude: 19.4380595, longitude: -99.1885031 },
    boxes: [{ occupied: 3, size: 's', total: 8 }, { occupied: 0, size: 'm', total: 2 }, { occupied: 0, size: 'l', total: 1 }],
    public_boxes: 0,
  }, {
    id: '5d559562a935bd5428de903a',
    timezone: 'America/Chicago',
    address: {
      street: 'Av Ejercito Nacional', number: '381', town: 'Granada', zip_code: '11520', city: 'Ciudad de México', state: 'Ciudad de México', country: 'México', parking: false,
    },
    service_days: [{
      weekday: 1, string_weekday: 'monday', opening_time: '09:00:00', closing_time: '18:00:00',
    }, {
      weekday: 2, string_weekday: 'tuesday', opening_time: '09:00:00', closing_time: '18:00:00',
    }, {
      weekday: 3, string_weekday: 'wednesday', opening_time: '09:00:00', closing_time: '18:00:00',
    }, {
      weekday: 4, string_weekday: 'thursday', opening_time: '09:00:00', closing_time: '18:00:00',
    }, {
      weekday: 5, string_weekday: 'friday', opening_time: '09:00:00', closing_time: '18:00:00',
    }],
    phone: '(811) 932-9873',
    internal_name: 'innovacion',
    uuid: '8889',
    coords: { latitude: 19.4380595, longitude: -99.1885031 },
    boxes: [{ occupied: 5, size: 's', total: 25 }, { occupied: 1, size: 'm', total: 7 }, { occupied: 0, size: 'l', total: 4 }],
    public_boxes: 0,
  }, {
    id: '5cedd61ca5fd8451509dbb19',
    timezone: 'America/Chicago',
    address: {
      street: 'Av Ejercito Nacional', number: '381', town: 'Granada', zip_code: '11520', city: 'Ciudad de México', state: 'Ciudad de México', country: 'México', parking: false,
    },
    service_days: [{
      weekday: 1, string_weekday: 'monday', opening_time: '09:00:00', closing_time: '18:00:00',
    }, {
      weekday: 2, string_weekday: 'tuesday', opening_time: '09:00:00', closing_time: '18:00:00',
    }, {
      weekday: 3, string_weekday: 'wednesday', opening_time: '09:00:00', closing_time: '18:00:00',
    }, {
      weekday: 4, string_weekday: 'thursday', opening_time: '09:00:00', closing_time: '18:00:00',
    }, {
      weekday: 5, string_weekday: 'friday', opening_time: '09:00:00', closing_time: '18:00:00',
    }],
    phone: '(811) 932-9873',
    internal_name: 'pruebas',
    uuid: '8888',
    coords: { latitude: 19.4380595, longitude: -99.1885031 },
    boxes: [{ occupied: 1, size: 's', total: 16 }, { occupied: 1, size: 'm', total: 8 }, { occupied: 1, size: 'l', total: 7 }],
    public_boxes: 0,
  }],
  total: 5,
  page: 1,
  page_size: 100,
  total_pages: 1,
  status_code: 200,
};
