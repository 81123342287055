// eslint-disable-next-line import/prefer-default-export
export const getReservations = {
  total: 11,
  page: 1,
  page_size: 15,
  total_pages: 1,
  reservations: [{
    is_confirmed: true, is_local: false, id: '5fce7ee88e57c900120d7e7c', location: '5fc94540644e9b60993b2d0e', event_history: [{ status: 'reserved', date: '2020-12-07T19:13:45.000Z' }, { status: 'delivered', date: '2020-12-07T19:14:23.000Z' }, { status: 'reopened', date: '2020-12-07T19:20:07.000Z' }, { status: 'reopened2', date: '2020-12-07T19:21:41.000Z' }], locker: { is_public: false, size: 'm', uuid: '008500109' }, shipment_id: 'CNB-TEST-ALE-004', delivery_due_date: '2020-12-07T19:13:44.000Z', created_at: '2020-12-07T19:13:44.000Z', updated_at: '2020-12-07T19:21:41.000Z', package_info: {}, additional: {}, contact_info: {}, recipient_info: {},
  }, {
    is_confirmed: true, is_local: false, id: '5fcfec32719e040018f05a46', location: '5fc94540644e9b60993b2d0e', event_history: [{ status: 'reserved', date: '2020-12-08T21:12:20.000Z' }, { status: 'delivered', date: '2020-12-08T22:01:27.000Z' }], locker: { is_public: false, size: 's', uuid: '008500205' }, shipment_id: 'CNB-TEST-BDG-001', delivery_due_date: '2020-12-08T21:12:18.000Z', created_at: '2020-12-08T21:12:18.000Z', updated_at: '2020-12-08T22:01:27.000Z', package_info: {}, additional: {}, contact_info: {}, recipient_info: {},
  }, {
    is_confirmed: true, is_local: false, id: '5fcfec49719e040018f05a4d', location: '5fc94540644e9b60993b2d0e', event_history: [{ status: 'reserved', date: '2020-12-08T21:12:41.000Z' }, { status: 'delivered', date: '2020-12-08T22:14:47.000Z' }, { status: 'reopened', date: '2020-12-08T22:36:32.000Z' }, { status: 'reopened2', date: '2020-12-08T22:38:27.000Z' }], locker: { is_public: false, size: 'm', uuid: '008500101' }, shipment_id: 'CNB-TEST-BDG-002', delivery_due_date: '2020-12-08T21:12:41.000Z', created_at: '2020-12-08T21:12:41.000Z', updated_at: '2020-12-08T22:38:27.000Z', package_info: {}, additional: {}, contact_info: {}, recipient_info: {},
  }],
  status_code: 200,
};
