import { createLogic } from 'redux-logic';
import { generatePath } from 'react-router-dom';
import * as types from '../constants/actions';
import * as endpoints from '../constants/api';
import { dateAgo } from '../utils/dates';

const getReservations = createLogic({
  type: types.RESERVATIONS_REQUEST,
  latest: true,
  processOptions: {
    successType: types.RESERVATIONS_REQUEST_SUCCEED,
    failType: types.RESERVATIONS_REQUEST_FAILED,
  },
  async process({ axios, action }) {
    const { locationId, status } = action.payload;
    const { reservations } = await axios.get(generatePath(endpoints.API_RESERVATIONS_PATH, {
      id: locationId,
    }), {
      params: {
        status,
        sort: '-updated_at',
        page_size: 30,
        from: dateAgo('3 months'),
      },
    });

    return {
      reservations: reservations.map((reservation) => ({
        id: reservation.id,
        shipmentId: reservation.shipment_id,
        eventHistory: reservation.event_history,
        updatedAt: reservation.updated_at,
        box: reservation.locker.boxes?.pop() || reservation.locker,
        recipientEmail: reservation.recipient_info?.email || '',
      })),
    };
  },
});

export default [
  getReservations,
];
