import * as actions from '../constants/actions';

export function getLocations() {
  return {
    type: actions.LOCATIONS_REQUEST,
    payload: {},
  };
}

export function selectLocation(location) {
  return {
    type: actions.LOCATION_SELECTED,
    payload: {
      selectedLocation: location,
    },
  };
}
