import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as routes from '../../constants/routes';

const PrivateRoute = (props) => {
  const isAuthenticated = useSelector((store) => store.session.authenticated);

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: routes.LOGIN_PATH,
          state: { from: props.location },
        }}
        push
      />
    );
  }

  return (
    <div className="Layout__container">
      <Route {...props} />
    </div>
  );
};

export default PrivateRoute;
