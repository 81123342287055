const LOK_PHONE_NUMBER = '55 8526 6046';
const LOK_SUPPORT_PHONE_NUMBER = '55 2858 0837';
const LOK_EMAIL = 'soporte@chazki.com';
const LOK_SUPPORT_EMAIL = 'hola@chazki.com';
const LOK_WEBSITE = 'https://www.lockers-hub.chazki.com/';
const LOK_SITE = 'lockers-hub.chazki';

export {
  LOK_PHONE_NUMBER,
  LOK_EMAIL,
  LOK_SITE,
  LOK_WEBSITE,
  LOK_SUPPORT_PHONE_NUMBER,
  LOK_SUPPORT_EMAIL,
};
