import React from 'react';
import {
  LOK_EMAIL,
  LOK_PHONE_NUMBER,
  LOK_SITE,
  LOK_WEBSITE,
  LOK_SUPPORT_EMAIL,
  LOK_SUPPORT_PHONE_NUMBER,
} from '../constants/support';
import './Support.less';

function Support() {
  return (
    <div>
      <h2>Soporte</h2>
      <h3>¿Necesitas ayuda?</h3>
      <p>Te ayudaremos en un momento</p>
      <p className="Support__p">
        <b>E-mail: </b>
        <a href={`mailto:${LOK_EMAIL}`}>{LOK_EMAIL}</a>
      </p>
      <p className="Support__p">
        <b>Chat online: </b>
        <a href={`${LOK_WEBSITE}`}>{`${LOK_SITE}.com`}</a>
      </p>
      <p className="Support__p">
        <b>Teléfono de soporte: </b>
        <a href={`tel:+52${LOK_PHONE_NUMBER}`}>{LOK_PHONE_NUMBER}</a>
      </p>
      <h3>¿Qué opinas de tu lok?</h3>
      <p> Coméntanos de tu experiencia </p>
      <p className="Support__p">
        <b>Contacto: </b>
        <a href={`mailto:${LOK_SUPPORT_EMAIL}`}>{LOK_SUPPORT_EMAIL}</a>
      </p>
      <p className="Support__p">
        <b>Teléfono: </b>
        <a href={`tel:+52${LOK_SUPPORT_PHONE_NUMBER}`}>
          {LOK_SUPPORT_PHONE_NUMBER}
        </a>
      </p>
    </div>
  );
}

export default Support;
