import initialState from './initialState';
import * as types from '../../constants/actions';
import * as err from '../../constants/errors';

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return {
        ...initialState,
        isLoading: true,
      };
    case types.LOGIN_REQUEST_SUCCEED:
      return {
        ...state,
        isError: false,
        isLoading: false,
      };
    case types.LOGIN_REQUEST_FAILED:
    case types.INVALID_AUTHENTICATION:
      return {
        isError: true,
        isLoading: false,
        message: err[action.payload.type] || err.DEFAULT_ERROR,
      };
    default:
      return {
        ...state,
      };
  }
};

export default authReducer;
