import * as actions from '../constants/actions';

// eslint-disable-next-line import/prefer-default-export
export function getReservations(locationId, status) {
  return {
    type: actions.RESERVATIONS_REQUEST,
    payload: {
      locationId,
      status,
    },
  };
}
