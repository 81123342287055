import initialState from './initialState';
import * as types from '../../constants/actions';
import * as err from '../../constants/errors';

const reservationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESERVATIONS_REQUEST:
      return {
        ...state,
        isError: false,
        isLoading: true,
        message: '',
      };
    case types.RESERVATIONS_REQUEST_SUCCEED:
      return {
        ...state,
        isError: false,
        isLoading: false,
        data: action.payload.reservations,
      };
    case types.RESERVATIONS_REQUEST_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        message: err.DEFAULT_ERROR,
      };
    case types.INVALID_AUTHENTICATION:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reservationReducer;
