import { createLogic } from 'redux-logic';
import * as types from '../constants/actions';
import * as endpoints from '../constants/api';
import { getAddressLine } from '../utils/address';

const getLocations = createLogic({
  type: types.LOCATIONS_REQUEST,
  latest: true,
  processOptions: {
    successType: types.LOCATIONS_REQUEST_SUCCEED,
    failType: types.LOCATIONS_REQUEST_FAILED,
  },
  async process({ axios }) {
    const { locations } = await axios.get(endpoints.API_LOCATIONS_PATH);

    return {
      locations: locations.map((location) => ({
        id: location.id,
        value: location.id,
        available: location.boxes.some((box) => box.occupied < box.total),
        name: location.internal_name,
        label: location.internal_name,
        uuid: location.uuid,
        address: getAddressLine(location.address),
      })),
    };
  },
});

export default [
  getLocations,
];
