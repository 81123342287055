import authLogic from './auth';
import locationLogic from './locations';
import reservationLogic from './reservations';

const allLogic = [
  ...authLogic,
  ...locationLogic,
  ...reservationLogic,
];

export default allLogic;
