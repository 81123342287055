import React from 'react';
import * as PropTypes from 'prop-types';
import { X } from 'react-feather';
import { Divider } from 'rsuite';
import { Modal } from 'react-responsive-modal';
import { SMALL_ICON } from '../../constants/iconSizes';
import * as util from '../../utils/reservations';
import 'react-responsive-modal/styles.css';

export default function ReservationModal(props) {
  const { show, onHide, data } = props;
  const deliveryDate = util.getDeliveryDate(data);
  const pickupDate = util.getPickupDate(data);

  return (
    <Modal
      open={show}
      onClose={onHide}
      center
      closeIcon={<X {...SMALL_ICON} />}
      classNames={{
        overlay: 'Modal__overlay',
        modal: 'Modal__container',
        closeButton: 'Modal__button--close',
      }}
    >
      <h6>
        Reservación
        { ' ' }
        { data.shipmentId }
      </h6>
      <p className="text-alt subtitle">
        Lok
        { ' ' }
        { data.locationName }
      </p>
      <Divider className="mb-3 mt-3" />
      <table className="Reservation__details">
        <tbody>
          <tr>
            <td>Casillero</td>
            <td>
              { data.box?.uuid.substr(4) }
              { ' - ' }
              { util.getSizeStr(data.box?.size) }
            </td>
          </tr>
          <tr>
            <td>Fecha Depósito</td>
            <td>
              { deliveryDate.date }
            </td>
          </tr>
          {
            !pickupDate && (
              <tr>
                <td>Hora Depósito</td>
                <td>
                  { deliveryDate.time }
                </td>
              </tr>
            )
          }
          {
            pickupDate && (
            <tr>
              <td>Fecha Recolección</td>
              <td>
                {' '}
                { pickupDate.date }
              </td>
            </tr>
            )
          }
          {
            pickupDate && (
            <tr>
              <td>Hora Recolección</td>
              <td>
                {' '}
                { pickupDate.time }
              </td>
            </tr>
            )
          }
          <tr>
            <td>Recolector</td>
            <td>
              {' '}
              { data.recipientEmail }
            </td>
          </tr>
          <tr>
            <td>Estatus</td>
            <td className="text-success">
              { pickupDate ? 'Recolectado' : 'Activa' }
            </td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
}

ReservationModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  data: PropTypes.object,
};
