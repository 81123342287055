import { createLogic } from 'redux-logic';
import * as types from '../constants/actions';
import * as actions from '../actions/auth';
import { getToken } from '../utils/auth';

const loginLogic = createLogic({
  type: types.LOGIN_REQUEST,
  latest: true,
  processOptions: {
    successType: actions.loginSucceed,
    failType: actions.loginFailed,
  },
  async process({ action }) {
    const { email, password } = action.payload;

    try {
      await getToken(email, password);
    } catch (err) {
      if (err.response) {
        throw err.response.data;
      }

      throw new Error('Cannot complete request');
    }
  },
});

export default [
  loginLogic,
];
