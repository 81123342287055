import React from 'react';
import { Grid } from 'rsuite';
import ReservationList from '../components/ReservationList/ReservationList';
import LocationPicker from '../components/LocationPicker/LocationPicker';

export default function Dashboard() {
  return (
    <Grid fluid>
      <h2>Reservaciones</h2>
      <LocationPicker />
      <ReservationList />
    </Grid>
  );
}
