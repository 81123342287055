// https://github.com/jquense/yup/blob/da23aea233d5883a73e74648c3cd93ea3b5d254c/src/locale.ts
/* eslint-disable no-template-curly-in-string */
export default {
  mixed: {
    required: 'Este campo es requerido',
    notType: 'Este campo es requerido',
  },
  string: {
    email: 'Correo inválido',
    min: 'Debe contener al menos ${min} caracteres',
    max: 'Debe contener a lo más ${max} caracteres',
  },
};
