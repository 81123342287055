import axios from 'axios';
import { sessionService } from 'redux-react-session';
import { refreshToken } from './auth';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
});

axiosInstance.interceptors.request.use(async (config) => {
  const token = await sessionService.loadSession();
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${token.access_token}`;

  return config;
});

axiosInstance.interceptors.response.use(
  (res) => res.data,
  async (err) => {
    if (!err.response) {
      return Promise.reject(err);
    }

    const originalRequest = err.config;

    // eslint-disable-next-line no-underscore-dangle
    if (err.response.status === 401 && !originalRequest._retry) {
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true;
      const credentials = await sessionService.loadSession();

      return refreshToken(credentials)
        .catch(() => {
          sessionService.invalidateSession();
          throw err.response.data;
        })
        .then(() => axiosInstance(originalRequest));
    }

    if (err.response.status === 401) {
      await sessionService.invalidateSession();
    }

    return Promise.reject(err.response.data);
  },
);

export default axiosInstance;
