import React from 'react';
import * as PropTypes from 'prop-types';
import './LocationPicker.less';

export default function LocationDisplay(props) {
  const { value } = props;

  return (
    <div className="Location__container">
      <h6>
        Lok&nbsp;
        {value.name}
      </h6>
      <p>{value.address}</p>
      <small className={value.available ? 'text-success' : 'text-danger'}>
        {value.available
          ? 'Casilleros disponibles'
          : 'Sin casilleros disponibles'}
      </small>
    </div>
  );
}

LocationDisplay.propTypes = {
  value: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    available: PropTypes.bool,
  }),
};
