import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nav, List, Message } from 'rsuite';
import ReservationEntry from './ReservationEntry';
import LoadingList from './ListLoading';
import * as actions from '../../actions/reservations';
import './ReservationList.less';

export default function ReservationList() {
  const dispatch = useDispatch();
  const [status, setStatus] = useState('active');
  const reservations = useSelector((store) => store.reservations.data);
  const locationId = useSelector((store) => store.locations.selectedLocation?.id);
  const locationName = useSelector((store) => store.locations.selectedLocation?.name);
  const isLoading = useSelector((store) => store.reservations.isLoading);
  const isError = useSelector((store) => store.reservations.isError);
  const errorMsg = useSelector((store) => store.reservations.message);

  useEffect(() => {
    if (locationId) {
      dispatch(actions.getReservations(locationId, status));
    }
  }, [dispatch, locationId, status]);

  const renderList = () => {
    if (reservations.length === 0) {
      return (
        <p className="text-muted text-copy">
          No se encontraron reservas.
        </p>
      );
    }

    return (
      <List className="Reservation__list">
        {
          reservations.map((res) => (
            <List.Item key={res.id}>
              <ReservationEntry data={{ ...res, locationName }} status={status} />
            </List.Item>
          ))
        }
      </List>
    );
  };

  return (
    <>
      {
        isError && <Message full closable showIcon type="error" description={errorMsg} />
      }
      <Nav activeKey={status} onSelect={(key) => setStatus(key)} appearance="subtle" justified className="Reservation__nav">
        <Nav.Item eventKey="active">
          Activas en locker
        </Nav.Item>
        <Nav.Item eventKey="finished">
          Finalizadas
        </Nav.Item>
      </Nav>
      {
        isLoading && <LoadingList />
      }
      {
        !isLoading && locationId && renderList()
      }
    </>
  );
}
