import initialState from './initialState';
import * as types from '../../constants/actions';

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOCATIONS_REQUEST:
      return {
        ...state,
        isError: false,
        isLoading: true,
        message: '',
      };
    case types.LOCATIONS_REQUEST_SUCCEED:
      return {
        ...state,
        isError: false,
        isLoading: false,
        data: action.payload.locations,
        selectedLocation: state.selectedLocation || action.payload.locations[0],
      };
    case types.LOCATIONS_REQUEST_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        message: '',
      };
    case types.LOCATION_SELECTED:
      return {
        ...state,
        ...action.payload,
      };
    case types.INVALID_AUTHENTICATION:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default locationReducer;
