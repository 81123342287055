import React, { useState } from 'react';
import { sessionService } from 'redux-react-session';
import './Navbar.less';
import {
  Navbar as NavbarRSuite,
  Sidenav,
  FlexboxGrid,
  Animation,
} from 'rsuite';
import {
  Menu,
  ChevronRight,
  LogOut,
  X,
} from 'react-feather';
import { NavLink, useRouteMatch, useHistory } from 'react-router-dom';
import logo from '../../logo.svg';
import { LARGE_ICON_RED, MEDIUM_ICON_RED, MEDIUM_ICON } from '../../constants/iconSizes';
import { LOGIN_PATH } from '../../constants/routes';
import NAVIGATION_ROUTES from './NavigationRoutes';

export default function NavBar() {
  const { Slide } = Animation;
  const match = useRouteMatch(LOGIN_PATH);
  const history = useHistory();
  const [isSidenavVisible, setSidenavVisible] = useState(false);
  const toggleSidenav = () => setSidenavVisible(!isSidenavVisible);
  const handleLogout = () => {
    sessionService.invalidateSession();
    // avoid sidenav open on re-login
    toggleSidenav();
    // avoid redirect from PrivateRoute with location state
    history.push(LOGIN_PATH);
  };

  if (match) {
    return null;
  }

  return (
    <>
      {/* Top Navigation Bar */}
      <NavbarRSuite>
        <FlexboxGrid justify="start" align="middle">
          <FlexboxGrid.Item colspan={8}>
            <button onClick={() => toggleSidenav()} className="btn__icon">
              {isSidenavVisible && <X {...LARGE_ICON_RED} />}
              {!isSidenavVisible && <Menu {...LARGE_ICON_RED} />}
            </button>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={8}>
            <NavLink to="/">
              <img id="logo" src={logo} alt="Lok" />
            </NavLink>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </NavbarRSuite>
      {/* Sidenav */}
      <Slide timeout={100} in={isSidenavVisible} placement="left">
        <Sidenav expanded={isSidenavVisible}>
          <Sidenav.Body>
            {NAVIGATION_ROUTES.map((nav) => (
              <NavLink
                exact
                key={nav.key}
                onClick={() => toggleSidenav()}
                className="Nav__item menu__item"
                to={nav.path}
              >
                <div>
                  <nav.icon {...MEDIUM_ICON} />
                  <span>{nav.name}</span>
                </div>
                <ChevronRight />
              </NavLink>
            ))}
          </Sidenav.Body>
          <button className="Nav__item Nav--logout" onClick={handleLogout}>
            <div>
              <LogOut {...MEDIUM_ICON_RED} />
              <span>Cerrar sesión</span>
            </div>
          </button>
        </Sidenav>
      </Slide>
    </>
  );
}
